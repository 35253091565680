import styled from 'styled-components/macro';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const SectionName = styled.div`
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.header3};
    line-height: 33px;
    margin-bottom: 16px;
    color: ${(props) => props.theme.colors.copyTextGray};
    @media (max-width: 1023px) {
        margin-left: 16px;
    }
`;
const Empty = styled.div`
    @media (max-width: 1023px) {
        margin-left: 16px;
    }
`;

const DemosList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 360px));
    gap: 16px;
    margin-bottom: 16px;
    @media (max-width: 1023px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        padding: 16px;
    }
`;

export { Container, DemosList, SectionName, Empty };
