import styled from 'styled-components/macro';
import { styled as muiStyled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { defaultTheme } from '../shared/styles/theme';

const HeaderContainer = styled.div<{ isTabletOrMobile: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
    @media (max-width: 1023px) {
        position: sticky;
        top: 0;
        left: 0;
        width: 100vw;
        background-color: ${(props) => props.theme.colors.white};
        border-bottom: 1px solid ${(props) => props.theme.colors.copyTextGray};
        z-index: 99;
    }
    @media (orientation: landscape) and (max-width: 1023px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;
const SubHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1023px) {
        position: relative;
        justify-content: flex-end;
    }
`;
const PageName = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.fonts.header1};
    line-height: 65px;
    color: ${(props) => props.theme.colors.ADTBlue};
    @media (max-width: 1023px) {
        position: absolute;
        font-size: 28px;
        line-height: 30px;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    @media (orientation: landscape) and (max-width: 1023px) {
        position: static;
        margin-left: auto;
    }
`;
const Filters = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    @media (max-width: 1023px) {
        flex-direction: column;
        margin-top: 8px;
        padding: 0 16px;
    }
    @media (orientation: landscape) and (max-width: 1023px) {
        padding: 0px 8px 0px 0px;
    }
`;
const AddButton = muiStyled(Button)`
    border: 1px solid ${defaultTheme.colors.ADTBlue};
    color: ${defaultTheme.colors.ADTBlue};
    line-height: 18px;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 24px;
    width: 222px;
    text-transform: none;
    @media (max-width: 1023px) {
      height: 66px;
      width: 66px;
      margin: 6px 8px 6px 6px;
  }
    @media (orientation: landscape) and (max-width: 1023px) {
        height: 40px;
        width: 40px;
        padding: 6px;
    }
    
`;
export { HeaderContainer, SubHeader, Filters, PageName, AddButton };
