import React from 'react';
import FormControl from '@mui/material/FormControl';
import {
    Select,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    SxProps,
    Theme,
} from '@mui/material';
import { DemoType } from './types';

interface Props {
    type: DemoType.Existing | DemoType.Internal | DemoType.Prospect | string;
    error: boolean | undefined;
    helperText: string | false | undefined;
    none?: boolean;
    sx: SxProps<Theme>;
    disabled?: boolean;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(
            field: T_1
        ): T_1 extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
}
export const DemoTypeSelect: React.FC<Props> = ({
    type,
    error,
    helperText,
    none,
    sx,
    disabled,
    handleChange,
}) => {
    const labelClass = error ? 'Mui-error' : '';
    return (
        <FormControl sx={sx}>
            <InputLabel className={labelClass} id="demoType-label">
                Demo type
            </InputLabel>
            <Select
                name="demoType"
                id="demoType"
                labelId="demoType-label"
                onChange={handleChange}
                variant="standard"
                value={type}
                disabled={disabled}
                input={<OutlinedInput label="Demo type" error={error} />}
            >
                {none ? (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                ) : null}
                <MenuItem value={DemoType.Internal}>
                    <span>Internal</span>
                </MenuItem>
                <MenuItem value={DemoType.Prospect}>
                    <span>Prospect</span>
                </MenuItem>
                <MenuItem value={DemoType.Existing}>
                    <span>Existing</span>
                </MenuItem>
            </Select>
            {error && (
                <FormHelperText error id="demoType-error">
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};
