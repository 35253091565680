import React from 'react';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import { CreatedTemplate, CustomerType } from './types';
import { StyledCard, Zone, CreatedDate, Icons } from './CardStyled';
import { defaultTheme } from '../../shared/styles/theme';

interface Props {
    template: CreatedTemplate;
    setTemplateToEdit: () => void;
}

export const Card: React.FC<Props> = ({ template, setTemplateToEdit }) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'America/Los_Angeles',
    } as const;
    const mediaIconAltText = template.media
        ? `${template.media?.mediaDescription}`
        : '';
    return (
        <StyledCard
            type={template.type}
            onClick={setTemplateToEdit}
            data-testid="TemplateCard"
        >
            <CreatedDate>
                Created Date:{' '}
                {template.createdDate ? (
                    <b>
                        {new Intl.DateTimeFormat('en-US', options).format(
                            new Date(template.createdDate)
                        )}
                    </b>
                ) : null}
            </CreatedDate>
            <Zone>
                Zone: {template.zoneNumber} - {template.zoneDescription}
            </Zone>

            <Icons>
                {template.type === CustomerType.Residential ? (
                    <HomeOutlinedIcon
                        sx={{
                            fontSize: 50,
                            color: defaultTheme.colors.moonBlue,
                        }}
                        titleAccess="Residential"
                    />
                ) : (
                    <HomeWorkOutlinedIcon
                        sx={{
                            fontSize: 50,
                            color: defaultTheme.colors.green,
                        }}
                        titleAccess="Small/Medium Business (SMB)"
                    />
                )}
                <OndemandVideoOutlinedIcon
                    sx={{
                        fontSize: 50,
                        color: template.media
                            ? defaultTheme.colors.ADTBlue
                            : defaultTheme.colors.grey4,
                    }}
                    titleAccess={mediaIconAltText}
                />
            </Icons>
        </StyledCard>
    );
};
