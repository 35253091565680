/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getProfile } from '../clients/profileClient';
import { UserProfile } from '../pages/profile/types';
import { RootState } from './store';

export interface UserProfileState {
    profile: UserProfile | null;
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: UserProfileState = {
    profile: null,
    loading: 'idle',
};

export const fetchUser = createAsyncThunk('user/getUserProfile', async () => {
    const response = await getProfile();
    return response;
});

export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        setProfile: (
            state: UserProfileState,
            action: PayloadAction<null | UserProfile>
        ) => {
            state.profile = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending';
                }
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                if (state.loading === 'pending') {
                    state.loading = 'idle';
                    state.profile = action.payload;
                }
            });
    },
});

export const { setProfile } = userProfileSlice.actions;

export const selectProfile = (state: RootState): UserProfileState =>
    state.userProfile;

export default userProfileSlice.reducer;
