import React, { useCallback, useEffect, useState, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SelectChangeEvent } from '@mui/material';
import { toast } from 'react-toastify';
import { usePermissionCheck } from '../../redux/hooks';
import { CardsContainer, Container } from './MediaStyled';
import { SearchBar } from '../../components/SearchBar';
import BasicModal from '../../components/BasicModal';
import { TypeSelect } from '../templates/TypeSelect';
import { ICreatedMedia } from './types';
import { CustomerType } from '../templates/types';
import { MediaCard } from './MediaCard';
import { AddMediaModal } from './AddMediaModal';
import { ConfirmDeleteModal } from '../../components/ConfirmDeleteModal';
import { deleteMedia, getMediaList } from '../../clients/mediaClient';
import { Loader } from '../../components/Loader';
import { Header } from '../../components/Header';

export const Media: React.FC = () => {
    const isTabletOrMobile = useMediaQuery('(max-width: 1023px)');
    const canDelete = usePermissionCheck('media.delete');
    const canCreate = usePermissionCheck('media.create');
    const [mediaList, setMediaList] = useState<ICreatedMedia[]>([]);
    const [filterByType, setFilterByType] = useState<'' | CustomerType>('');
    const [search, setSearch] = useState<string>('');
    const [openAddMediaModal, setOpenAddMediaModal] = useState(false);
    const [mediaToEdit, setMediaToEdit] = useState<ICreatedMedia>();
    const [mediaToDelete, setMediaToDelete] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [clickedMedia, setClickedMedia] = useState(new Map());

    const isMounted = useRef(true);
    const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const deletedMediaIds = useRef<Set<string>>(new Set());

    const fetchMedia = useCallback(async () => {
        if (!isMounted.current) return;

        setLoading(true);
        try {
            const response = await getMediaList(search, filterByType);
            if (isMounted.current) {
                // Filter out any media that has been deleted
                const filteredMedia = (response?.items || []).filter(
                    (media) => !deletedMediaIds.current.has(media.mediaId)
                );
                setMediaList(filteredMedia);
            }
        } catch (error) {
            toast.error('Server error. Please try again', {
                position: toast.POSITION.TOP_CENTER,
            });
        } finally {
            if (isMounted.current) {
                setLoading(false);
            }
        }
    }, [search, filterByType]);

    const scheduleRefresh = useCallback(
        (delay = 1000) => {
            if (refreshTimeoutRef.current) {
                clearTimeout(refreshTimeoutRef.current);
            }
            refreshTimeoutRef.current = setTimeout(() => {
                if (isMounted.current) {
                    fetchMedia();
                }
            }, delay);
        },
        [fetchMedia]
    );

    const onModalClose = useCallback((): void => {
        setOpenAddMediaModal(false);
        setMediaToEdit(undefined);
        setMediaToDelete(undefined);
        setClickedMedia(new Map());
        scheduleRefresh(); // Schedule a refresh when modal closes (for both add and edit)
    }, [scheduleRefresh]);

    const handleOpen = (): void => setOpenAddMediaModal(true);
    const handleClose = (): void => {
        onModalClose();
    };
    const handleFilterByType = (event: SelectChangeEvent) => {
        setFilterByType(event.target.value as '' | CustomerType);
    };
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 2 || event.target.value === '')
            setSearch(event.target.value);
    };
    const onMediaDelete = useCallback(async () => {
        if (mediaToDelete) {
            setLoading(true);
            try {
                await deleteMedia(mediaToDelete);
                if (isMounted.current) {
                    setMediaList((prevMedia) =>
                        prevMedia.filter(
                            (media) => media.mediaId !== mediaToDelete
                        )
                    );
                    deletedMediaIds.current.add(mediaToDelete);
                    onModalClose();
                    // Schedule two refreshes: one immediately and one after a delay
                    fetchMedia();
                    scheduleRefresh(5000); // Schedule another refresh after 5 seconds
                }
            } catch (error) {
                toast.error('Error deleting media', {
                    position: toast.POSITION.TOP_CENTER,
                });
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        }
    }, [mediaToDelete, onModalClose, scheduleRefresh, fetchMedia]);

    useEffect(() => {
        fetchMedia();
        return () => {
            isMounted.current = false;
            if (refreshTimeoutRef.current) {
                clearTimeout(refreshTimeoutRef.current);
            }
        };
    }, [fetchMedia]);

    const filteredMediaList = mediaList.filter((media) => {
        const matchesType = filterByType
            ? media.customerType === filterByType
            : true;
        const matchesSearch = search
            ? media.mediaDescription
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) ||
              media.mediaFile?.name.toLowerCase().includes(search.toLowerCase())
            : true;
        return matchesSearch && matchesType;
    });

    return (
        <div>
            <Container>
                <Header
                    isTabletOrMobile={isTabletOrMobile}
                    pageName="Media"
                    handleAddPress={handleOpen}
                    addButtonContent="Add Media"
                    canCreate={canCreate}
                >
                    <SearchBar
                        handleChange={handleSearch}
                        placeholder="Search..."
                    />
                    <TypeSelect
                        type={filterByType}
                        handleChange={handleFilterByType}
                        error={false}
                        helperText=""
                        none
                        sx={{
                            m: isTabletOrMobile ? '16px 0' : '16px 0 16px 8px',
                            width: isTabletOrMobile ? '100%' : '40%',
                        }}
                    />
                </Header>
                <CardsContainer>
                    {filteredMediaList?.map((media) => (
                        <MediaCard
                            key={media.mediaId}
                            media={media}
                            setMediaToEdit={() => setMediaToEdit(media)}
                            setClickedMedia={() =>
                                setClickedMedia(
                                    new Map(
                                        clickedMedia.set(media.mediaId, true)
                                    )
                                )
                            }
                            clickedMedia={clickedMedia}
                        />
                    ))}
                </CardsContainer>
                <BasicModal
                    open={openAddMediaModal || !!mediaToEdit}
                    handleClose={handleClose}
                >
                    <AddMediaModal
                        handleClose={onModalClose}
                        mediaToEdit={mediaToEdit}
                        mediaList={mediaList}
                        setMediaToDelete={(
                            e: React.SyntheticEvent<HTMLButtonElement>
                        ) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMediaToDelete(mediaToEdit?.mediaId);
                        }}
                        canDelete={canDelete}
                    />
                </BasicModal>
                <BasicModal open={!!mediaToDelete} handleClose={handleClose}>
                    <ConfirmDeleteModal
                        message="Are you sure you want to delete this media?"
                        close={onModalClose}
                        confirm={onMediaDelete}
                    />
                </BasicModal>
                {loading && <Loader />}
            </Container>
        </div>
    );
};
