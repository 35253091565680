import styled from 'styled-components/macro';
import { CustomerType } from '../templates/types';

const StyledCard = styled.div<{ type: CustomerType }>`
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 6px, rgba(0, 0, 0, 0.24) 0px 2px 6px;
    border-top: 8px solid
        ${(props) =>
            props.type === CustomerType.Residential
                ? props.theme.colors.moonBlue
                : props.theme.colors.green};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 26px;
    background-color: ${(props) => props.theme.colors.white};
    max-height: 350px;
    cursor: pointer;
    :hover {
        background-color: ${(props) => props.theme.colors.backgroundGray};
    }
`;

const CreatedDate = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: ${(props) => props.theme.fonts.subtext};
    line-height: 14px;
    color: ${(props) => props.theme.colors.copyTextGray};
`;
const Description = styled.div`
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.subHeading};
    line-height: 19px;
    color: ${(props) => props.theme.colors.black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const Icons = styled.div`
    display: flex;
    justify-content: space-between;
`;
const VideoContainer = styled.div`
    display: flex;
    position: relative;
    margin: 0 16px;
    height: 12rem;
`;
const PreviewThumbnail = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

const Preview = styled.video`
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

export {
    StyledCard,
    Preview,
    PreviewThumbnail,
    CreatedDate,
    Description,
    Icons,
    VideoContainer,
};
