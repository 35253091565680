import React from 'react';
import styled from 'styled-components';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Button from '@mui/material/Button';
import { DeliveryType, ICreatedDemo } from './types';
import { CustomerType } from '../templates/types';
import { defaultTheme } from '../../shared/styles/theme';

const StyledCard = styled.div<{ type: CustomerType }>`
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 6px, rgba(0, 0, 0, 0.24) 0px 2px 6px;
    border-radius: 10px;
    border-top: 8px solid
        ${(props) =>
            props.type === CustomerType.Residential
                ? props.theme.colors.moonBlue
                : props.theme.colors.green};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 8px;
    padding: 16px 26px;
    cursor: pointer;
    :hover {
        background-color: ${(props) => props.theme.colors.backgroundGray};
    }
`;
const Created = styled.div`
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    font-size: ${(props) => props.theme.fonts.subtext};
    line-height: 14px;
    color: ${(props) => props.theme.colors.copyTextGray};
`;
const CustomerName = styled.div`
    display: flex;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.header4};
    line-height: 25px;
    color: ${(props) => props.theme.colors.grey1};
    overflow: hidden;
`;
const Type = styled.div<{ type: string }>`
    display: flex;
    justify-content: space-between;
    font-weight: 900;
    font-size: 16px;
    color: ${(props) =>
        props.type === CustomerType.Residential
            ? props.theme.colors.moonBlue
            : props.theme.colors.green};
    margin-bottom: 8px;
`;

const Incidents = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: ${(props) => props.theme.fonts.text};
    line-height: 16px;
    color: ${(props) => props.theme.colors.grey1};
`;

const Address = styled.div`
    display: flex;
    font-weight: 400;
    font-size: ${(props) => props.theme.fonts.text};
    line-height: 16px;
    color: ${(props) => props.theme.colors.grey1};
    margin: 8px 0;
    overflow: hidden;
`;

const StyledBottomBar = styled.div`
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
`;

interface Props {
    demo: ICreatedDemo;
    canRun: boolean;
    setDemoToRun: (demo: ICreatedDemo) => void;
    setDemoToEdit: () => void;
}

export const Card: React.FC<Props> = ({
    demo,
    canRun,
    setDemoToRun,
    setDemoToEdit,
}) => {
    const onRunClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setDemoToRun(demo);
    };
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'America/Los_Angeles',
    } as const;
    return (
        <StyledCard
            type={demo.customerType}
            onClick={setDemoToEdit}
            data-testid={
                demo.isArchived ? 'demo-card-archived' : 'demo-card-live'
            }
        >
            <div>
                <Created>
                    Created:{' '}
                    <b>
                        {new Intl.DateTimeFormat('en-US', options).format(
                            new Date(demo.createdDate)
                        )}
                    </b>
                </Created>
                <CustomerName>{demo.customerName}</CustomerName>
                <Address>{demo.customerAddress}</Address>
                <Type type={demo.customerType}>
                    {demo.demoType.charAt(0).toUpperCase() +
                        demo.demoType.slice(1)}
                    {demo.deliveryType === DeliveryType.InPerson ? (
                        <PeopleOutlineOutlinedIcon titleAccess="In Person" />
                    ) : (
                        <ContactPhoneOutlinedIcon titleAccess="Virtual" />
                    )}
                </Type>
                <Incidents>
                    <div>
                        Incidents:{' '}
                        <b>{demo.incidentsRun ? demo.incidentsRun : 0}</b>
                    </div>
                    Last:{' '}
                    {demo.lastRunDate
                        ? new Intl.DateTimeFormat('en-US', options).format(
                              new Date(demo.lastRunDate)
                          )
                        : '-'}
                </Incidents>
                <p />
            </div>
            <StyledBottomBar>
                {demo.customerType === CustomerType.Residential ? (
                    <HomeOutlinedIcon
                        sx={{
                            fontSize: 50,
                            color: defaultTheme.colors.moonBlue,
                        }}
                        titleAccess="Residential"
                    />
                ) : (
                    <HomeWorkOutlinedIcon
                        sx={{
                            fontSize: 50,
                            color: defaultTheme.colors.green,
                        }}
                        titleAccess="Small/Medium Business (SMB)"
                    />
                )}
                {demo.isArchived ? null : (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onRunClick}
                        disabled={!canRun}
                        sx={{
                            backgroundColor:
                                demo.customerType === CustomerType.Residential
                                    ? defaultTheme.colors.moonBlue
                                    : defaultTheme.colors.green,
                        }}
                    >
                        Start Incident
                    </Button>
                )}
            </StyledBottomBar>
        </StyledCard>
    );
};
