import { CustomerType } from '../templates/types';

export enum DemoType {
    Prospect = 'prospect',
    Existing = 'existing',
    Internal = 'internal',
}

export enum DeliveryType {
    Virtual = 'virtual',
    InPerson = 'in person',
}

export interface IDemo {
    customerName: string;
    customerType: CustomerType;
    customerAddress: string;
    deliveryType: DeliveryType;
    demoType: DemoType;
    participant0: { name?: string; phone?: string; selected?: boolean };
    participant1: { name?: string; phone?: string; selected?: boolean };
    participant2: { name?: string; phone?: string; selected?: boolean };
    participant3: { name?: string; phone?: string; selected?: boolean };
}
export interface ICreatedDemo extends IDemo {
    demoId: string;
    createdDate: string;
    lastRunDate: string;
    incidentsRun: string;
    isArchived: boolean;
}
export interface GetDemosResponse {
    count: number;
    items: ICreatedDemo[];
}

export interface Incident {
    customerAddress: string;
    customerName: string;
    demoId: string;
    mediaId: string;
    zoneTemplateId: string;
    zoneDescription: string;
    zoneNumber: string;
    avs01: boolean;
    participant0: boolean;
    participant1: boolean;
    participant2: boolean;
    participant3: boolean;
}
