import { toast } from 'react-toastify';
import { config } from '../config';
import { UserProfile } from '../pages/profile/types';
import { get, patch } from './axiosService';

export const getProfile = async () => {
    try {
        const response = await get<UserProfile>(
            `${config.clients.profileClient.baseUrl}`
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const updateProfile = async (data: Partial<UserProfile>) => {
    try {
        const response = await patch(
            `${config.clients.profileClient.baseUrl}`,
            data
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};
