import styled from 'styled-components/macro';

const Container = styled.div`
    @media (max-width: 1023px) {
        width: 100vw;
    }
    width: 40vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 16px;
`;

const SubTitle = styled.div`
    display: flex;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.header4};
    line-height: 25px;
    color: ${(props) => props.theme.colors.copyTextGray};
    margin-bottom: 16px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    gap: 8px;
    @media (max-width: 1023px) {
        position: sticky;
        bottom: 8px;
        background-color: ${(props) => props.theme.colors.white};
        z-index: 9;
    }
`;

export { Container, SubTitle, ButtonsContainer };
