import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { oktaAuth } from '../MainLayout';

export type Method = 'GET' | 'POST' | 'PATCH' | 'DELETE';

export interface QueryParams {
    search?: string;
    filter?: string;
    page?: string;
}

export const makeRequest = async <T, D>(
    method: Method,
    url: string,
    params?: QueryParams,
    data?: D
): Promise<T> => {
    const idToken = oktaAuth.getIdToken();
    const config: AxiosRequestConfig = {
        method,
        url,
        headers: {
            Authorization: `Bearer ${idToken}`,
        },
        params,
        data,
    };
    const response: AxiosResponse<T> = await axios.request<T>(config);
    return response.data;
};

export const get = async <T>(url: string, params?: QueryParams): Promise<T> => {
    const response = await makeRequest<T, undefined>('GET', url, params);
    return response;
};

export const post = async <T, U>(url: string, data?: U): Promise<T> => {
    const response = await makeRequest<T, U>('POST', url, undefined, data);
    return response;
};

export const patch = async <T, U>(url: string, data?: U): Promise<T> => {
    const response = await makeRequest<T, U>('PATCH', url, undefined, data);
    return response;
};

export const remove = async <T>(url: string): Promise<T> => {
    const response = await makeRequest<T, undefined>('DELETE', url);
    return response;
};
