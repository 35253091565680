import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useOktaAuth } from '@okta/okta-react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import parsePhoneNumber from 'libphonenumber-js';
import { Container, Form, PageName, ProfileInfo } from './ProfileStyled';
import { Loader } from '../../components/Loader';
import {
    useAppDispatch,
    useAppSelector,
    usePermissionCheck,
} from '../../redux/hooks';
import { selectProfile } from '../../redux/userProfileSlice';
import { onProfileSubmit } from './utils';
import { UserProfile } from './types';
import { PhoneInput } from '../../components/PhoneInput';
import { ConfirmDeleteModal } from '../../components/ConfirmDeleteModal';
import BasicModal from '../../components/BasicModal';

const validationSchema = yup.object({
    phone: yup
        .string()
        .test('phone', 'Please enter contact mobile phone', (value) => {
            if (value) {
                const phone = parsePhoneNumber(value, 'US');
                if (phone?.country !== 'US') {
                    return false;
                }
                return !!phone?.isValid();
            }
            return true;
        }),
});

const Profile: React.FC<{ showPhoneInput?: boolean }> = ({
    showPhoneInput = false,
}) => {
    const { profile } = useAppSelector(selectProfile);
    const hasEditPermission = usePermissionCheck('profile.edit');
    const [loading, setLoading] = useState(false);
    const [phoneToRemove, setPhoneToRemove] = useState(false);

    const dispatch = useAppDispatch();
    const { oktaAuth } = useOktaAuth();

    const formik = useFormik({
        initialValues: {
            firstName: profile?.firstName || '',
            lastName: profile?.lastName || '',
            email: profile?.email || '',
            phone: profile?.phone || '',
        },
        validationSchema,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onSubmit,
    });
    async function onSubmit(values: any) {
        if (hasEditPermission)
            onProfileSubmit(
                values,
                formik.initialValues as UserProfile,
                dispatch,
                setLoading
            );
    }
    const onRemoveClick = async () => {
        await formik.setFieldValue('phone', '');
        formik.handleSubmit();
    };
    const onPhoneFocus = () => {
        formik.setFieldTouched('phone', true);
        formik.setFieldValue('phone', '');
    };
    const onPhoneBlur = () => {
        const valid = !formik.errors.phone && !!formik.values.phone;
        if (valid) {
            formik.handleSubmit();
        } else {
            formik.setFieldValue('phone', formik.initialValues.phone);
            formik.setFieldTouched('phone', false);
        }
    };
    const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(event);
        formik.validateField('phone');
    };

    return (
        <Container data-testid="profile-page">
            <PageName>Profile</PageName>

            <ProfileInfo>
                <Form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        disabled
                        id="name"
                        name="name"
                        label="Name"
                        // eslint-disable-next-line max-len
                        value={`${formik.values.firstName} ${formik.values.lastName}`}
                        onChange={() => {}}
                        sx={{ m: '16px 0' }}
                    />
                    <TextField
                        fullWidth
                        disabled
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={() => {}}
                        sx={{ m: '16px 0' }}
                    />
                    {showPhoneInput && (
                        <PhoneInput
                            id="phone"
                            name="phone"
                            label="Phone"
                            value={formik.values.phone}
                            onChange={onPhoneChange}
                            error={
                                formik.touched.phone &&
                                Boolean(formik.errors.phone)
                            }
                            helperText={
                                formik.touched.phone && formik.errors.phone
                            }
                            sx={{ m: '16px 0' }}
                            onBlur={onPhoneBlur}
                            onFocus={onPhoneFocus}
                            onRemoveClick={() => setPhoneToRemove(true)}
                        />
                    )}
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={() => oktaAuth.signOut()}
                    >
                        Sign-out
                    </Button>
                </Form>
            </ProfileInfo>
            <BasicModal
                open={!!phoneToRemove}
                handleClose={() => setPhoneToRemove(false)}
            >
                <ConfirmDeleteModal
                    message="Are you sure you want to remove your phone number?"
                    confirm={onRemoveClick}
                    close={() => setPhoneToRemove(false)}
                />
            </BasicModal>
            {loading ? <Loader /> : null}
        </Container>
    );
};

export default Profile;
