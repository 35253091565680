import React from 'react';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import FormControl from '@mui/material/FormControl';
import {
    Select,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    SxProps,
    Theme,
    SelectChangeEvent,
} from '@mui/material';
import { defaultTheme } from '../../shared/styles/theme';
import { CreatedTemplate } from '../templates/types';

interface Props {
    selectedTemplate: string;
    error: boolean | undefined;
    helperText: string | false | undefined;
    none?: boolean;
    sx: SxProps<Theme>;
    templates: CreatedTemplate[] | undefined;
    handleChange: (event: SelectChangeEvent<string>) => Promise<void>;
}
export const ZoneTemplateSelect: React.FC<Props> = ({
    selectedTemplate,
    error,
    helperText,
    none,
    sx,
    templates,
    handleChange,
}) => {
    return (
        <FormControl sx={sx}>
            <InputLabel id="zoneTemplateId-label">Zone Template</InputLabel>
            <Select
                name="zoneTemplateId"
                id="zoneTemplateId"
                labelId="zoneTemplateId-label"
                onChange={handleChange}
                variant="standard"
                value={selectedTemplate}
                input={<OutlinedInput label="Zone Template" error={error} />}
            >
                {none ? (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                ) : null}
                {templates?.map((template) => (
                    <MenuItem
                        value={template.zoneTemplateId}
                        key={template.zoneTemplateId}
                    >
                        <span>
                            {`${template.zoneNumber} : ${template.zoneDescription} `}
                        </span>
                        &nbsp;
                        {template.media?.mediaId ? (
                            <OndemandVideoOutlinedIcon
                                sx={{
                                    fontSize: 24,
                                    color: template.media
                                        ? defaultTheme.colors.ADTBlue
                                        : defaultTheme.colors.grey4,
                                }}
                            />
                        ) : null}
                    </MenuItem>
                ))}
            </Select>
            {error && (
                <FormHelperText error id="zoneTemplate-error">
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};
