import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { FileInput } from '../../components/FileInput';
import { Loader } from '../../components/Loader';
import { TypeSelect } from '../templates/TypeSelect';
import {
    ButtonsContainer,
    Container,
    DownloadButton,
    Form,
    RowContainer,
    Title,
    Subtitle,
    LinkedTemplate,
    LinkedTemplateContainer,
} from './AddMediaModalStyled';
import { ICreatedMedia } from './types';
import { handleMediaSubmit, isMediaDescriptionUnique } from './utils';
import { usePermissionCheck } from '../../redux/hooks';

interface Props {
    handleClose: () => void;
    mediaToEdit: ICreatedMedia | undefined;
    mediaList: ICreatedMedia[] | undefined;
    setMediaToDelete: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
    canDelete: boolean;
}

export const AddMediaModal: React.FC<Props> = ({
    mediaToEdit,
    mediaList,
    canDelete,
    handleClose,
    setMediaToDelete,
}) => {
    const [loading, setLoading] = useState(false);
    const hasEditPermission = usePermissionCheck('media.edit');
    const canEdit =
        !mediaToEdit ||
        (!!mediaToEdit &&
            hasEditPermission &&
            !mediaToEdit?.isAssignedToTemplate);
    const validationSchema = yup.object({
        customerType: yup
            .string()
            .required('Customer type is required')
            .max(50, 'Must be less then 50 symbols'),
        mediaDescription: yup
            .string()
            .required('Description is required')
            .max(150, 'Must be less then 150 symbols')
            .test(
                'uniqueNumberAndDescription',
                'Description shall be unique',
                (value) => {
                    return isMediaDescriptionUnique(
                        value,
                        mediaList,
                        mediaToEdit
                    );
                }
            ),
        mediaFile: yup
            .mixed()
            .test(
                'filenameLengthCheck',
                'Please limit filename length to 64 characters',
                (file) => {
                    const maxFileLength = 68;
                    const hasFile = !!file;
                    return !hasFile || file.path.length <= maxFileLength; // Filename + .mp4 = 68
                }
            )
            .required('File is required'),
    });

    const formik = useFormik({
        initialValues: {
            customerType: mediaToEdit?.customerType ?? '',
            mediaDescription: mediaToEdit?.mediaDescription ?? '',
            mediaFile: mediaToEdit?.mediaFile ?? undefined,
        },
        validationSchema,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onSubmit,
    });
    async function onSubmit(values: any) {
        handleMediaSubmit(
            values,
            mediaToEdit,
            formik.initialValues,
            setLoading,
            handleClose
        );
    }
    const handleBlurWhenEdit = () => {
        if (!!mediaToEdit && canEdit) formik.handleSubmit();
    };

    return (
        <Container>
            <Form onSubmit={formik.handleSubmit}>
                <Title>
                    {mediaToEdit ? 'Edit media' : 'Add media (.mp4 only)'}
                </Title>
                <RowContainer>
                    <TextField
                        fullWidth
                        multiline
                        id="mediaDescription"
                        name="mediaDescription"
                        label="Media description"
                        value={formik.values.mediaDescription}
                        onChange={formik.handleChange}
                        onBlur={handleBlurWhenEdit}
                        error={
                            formik.touched.mediaDescription &&
                            Boolean(formik.errors.mediaDescription)
                        }
                        helperText={
                            formik.touched.mediaDescription &&
                            formik.errors.mediaDescription
                        }
                        sx={{ m: '16px 0' }}
                        inputProps={{
                            maxLength: 150,
                        }}
                        disabled={!canEdit}
                    />
                    <TypeSelect
                        type={formik.values.customerType}
                        inputName="customerType"
                        handleChange={formik.handleChange}
                        onBlur={handleBlurWhenEdit}
                        error={
                            formik.touched.customerType &&
                            Boolean(formik.errors.customerType)
                        }
                        helperText={
                            formik.touched.customerType &&
                            formik.errors.customerType
                        }
                        sx={{
                            m: '16px 0',
                            width: '35%',
                        }}
                        disabled={!canEdit}
                    />
                </RowContainer>
                <FileInput
                    accept={{
                        'video/mp4': ['.mp4'],
                    }}
                    inputName="mediaFile"
                    label="Media File"
                    error={Boolean(formik.errors.mediaFile)}
                    helperText={formik.errors.mediaFile}
                    file={formik.values.mediaFile}
                    setFieldValue={formik.setFieldValue}
                    setFieldError={formik.setFieldError}
                    onBlur={handleBlurWhenEdit}
                    disabled={!canEdit}
                    hideThumb={
                        !mediaToEdit ||
                        formik.values.mediaFile !== mediaToEdit.mediaFile
                    }
                />
                {mediaToEdit?.isAssignedToTemplate ? (
                    <LinkedTemplateContainer>
                        <Subtitle>Linked Templates:</Subtitle>
                        {mediaToEdit?.linkedTemplates?.map((template) => {
                            return (
                                <LinkedTemplate>
                                    Zone {template.zoneNumber} -
                                    {template.zoneDescription}
                                </LinkedTemplate>
                            );
                        })}
                    </LinkedTemplateContainer>
                ) : (
                    <Container />
                )}
                {mediaToEdit ? (
                    <ButtonsContainer>
                        <div>
                            <Button
                                variant="outlined"
                                name="archive"
                                onClick={setMediaToDelete}
                                disabled={
                                    !canDelete ||
                                    mediaToEdit?.isAssignedToTemplate
                                }
                                sx={{ mr: '18px', width: '128px' }}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="outlined"
                                name="download"
                                sx={{ width: '128px' }}
                            >
                                <DownloadButton
                                    download
                                    href={mediaToEdit.mediaFile?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Download
                                </DownloadButton>
                            </Button>
                        </div>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleClose}
                            sx={{ width: '128px' }}
                        >
                            Close
                        </Button>
                    </ButtonsContainer>
                ) : (
                    <ButtonsContainer>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{ width: '128px' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            name="save"
                            type="submit"
                            sx={{ width: '128px' }}
                        >
                            Save
                        </Button>
                    </ButtonsContainer>
                )}
            </Form>
            {loading ? <Loader /> : null}
        </Container>
    );
};
