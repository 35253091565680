import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import './shared/styles/global.css';
import MainLayout from './MainLayout';
import { defaultTheme } from './shared/styles/theme';
import { store } from './redux/store';

const muiTheme = createTheme({
    palette: {
        primary: {
            main: defaultTheme.colors.ADTBlue,
            dark: defaultTheme.colors.moonBlue,
        },
    },
    typography: {
        allVariants: {
            fontFamily: 'Brown',
            textTransform: 'none',
            fontSize: 14,
            color: defaultTheme.colors.ADTBlue,
        },
    },
    shape: {
        borderRadius: 24,
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: defaultTheme.colors.copyTextGray,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: 11,
                },
            },
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <MuiThemeProvider theme={muiTheme}>
                    <ThemeProvider theme={defaultTheme}>
                        <MainLayout />
                    </ThemeProvider>
                </MuiThemeProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
