import styled from 'styled-components/macro';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 360px));
    gap: 16px;
    @media (max-width: 1023px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        padding: 16px;
    }
`;

export { Container, CardsContainer };
