import React, { useCallback, useEffect, useState } from 'react';
import { Button, useMediaQuery } from '@mui/material';
import { Container } from './DemosStyled';
import { ICreatedDemo } from './types';
import { SearchBar } from '../../components/SearchBar';
import BasicModal from '../../components/BasicModal';
import { usePermissionCheck } from '../../redux/hooks';
import { CreateDemoModal } from './CreateDemoModal';
import { CreateDemoConfirmModal } from './CreateDemoConfirmModal';
import { RunIncidentModal } from './RunIncidentModal';
import { CreatedTemplate } from '../templates/types';
import { Loader } from '../../components/Loader';
import { getTemplates } from '../../clients/templatesClient';
import { Header } from '../../components/Header';
import { getDemos } from '../../clients/demosClient';
import { defaultTheme } from '../../shared/styles/theme';
import { handleSearch, onModalClose } from './utils';
import { DemosListSection } from './DemosListSection';

interface Props {
    showModal?: boolean;
    showSubmodal?: boolean;
    isTest?: boolean;
}

export const Demos: React.FC<Props> = ({
    showModal = false,
    showSubmodal = false,
    isTest = false,
}) => {
    const isTabletOrMobile = useMediaQuery('(max-width: 1023px)');
    const canCreate = usePermissionCheck('demos.create');
    const [templates, setTemplates] = useState<CreatedTemplate[] | null>(null);
    const [demos, setDemos] = useState<ICreatedDemo[]>([]);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [openCreateDemoModal, setOpenCreateDemoModal] = useState(showModal);
    const [openDemoSubmodal, setOpenDemoSubmodal] = useState(showSubmodal);
    const [demoToEdit, setDemoToEdit] = useState<ICreatedDemo>();
    const [demoToRun, setDemoToRun] = useState<ICreatedDemo>();
    const handleCreateDemo = () => {
        setOpenCreateDemoModal(true);
    };

    const fetchTemplates = useCallback(async () => {
        const response = await getTemplates();
        if (response) setTemplates(response.items);
    }, []);
    const fetchDemos = useCallback(async () => {
        const response = await getDemos(search, String(page));
        if (response) {
            setCount(response.count);
            if (page > 1) {
                setDemos((l) => [...l, ...response.items]);
            } else {
                setDemos(response.items);
            }
        }
    }, [search, page]);

    const handleCloseModal = (): void => {
        onModalClose(
            setOpenCreateDemoModal,
            setOpenDemoSubmodal,
            setDemoToEdit,
            setDemoToRun,
            fetchDemos
        );
    };

    const handleClose = (): void => {
        if (!demoToEdit && !openDemoSubmodal) {
            setOpenDemoSubmodal(true);
        } else {
            onModalClose(
                setOpenCreateDemoModal,
                setOpenDemoSubmodal,
                setDemoToEdit,
                setDemoToRun,
                fetchDemos
            );
        }
    };

    const closeSubmodal = (): void => {
        setOpenDemoSubmodal(false);
    };

    useEffect(() => {
        setLoading(true);

        const fetchData = async () => {
            await Promise.all([fetchTemplates(), fetchDemos()]);
            setLoading(false);
        };

        fetchData();
    }, [search, fetchDemos, fetchTemplates]);

    return (
        <Container>
            <Header
                isTabletOrMobile={isTabletOrMobile}
                pageName="Demos"
                handleAddPress={handleCreateDemo}
                addButtonContent="Create Demo"
                canCreate={canCreate || isTest}
            >
                <SearchBar
                    handleChange={(e) => handleSearch(e, setPage, setSearch)}
                    placeholder="Search..."
                    sx={{
                        m: isTabletOrMobile ? '16px 0' : '16px 0 16px 8px',
                    }}
                />
            </Header>
            <DemosListSection
                demos={demos}
                setDemoToEdit={setDemoToEdit}
                setDemoToRun={setDemoToRun}
            />
            {demos.length < count ? (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setPage(page + 1)}
                    sx={{
                        backgroundColor: defaultTheme.colors.moonBlue,
                        width: '134px',
                        height: '48px',
                        alignSelf: 'center',
                    }}
                >
                    Load More
                </Button>
            ) : null}
            <BasicModal
                open={openCreateDemoModal || !!demoToEdit}
                handleClose={handleClose}
            >
                <CreateDemoModal
                    handleSave={handleCloseModal}
                    handleClose={handleClose}
                    demoToEdit={demoToEdit}
                    setDemoToRun={setDemoToRun}
                />
            </BasicModal>
            <BasicModal open={openDemoSubmodal} handleClose={handleClose}>
                <CreateDemoConfirmModal
                    handleClose={handleClose}
                    handleCloseSubmodal={closeSubmodal}
                />
            </BasicModal>
            <BasicModal open={!!demoToRun} handleClose={handleClose}>
                <RunIncidentModal
                    templates={templates}
                    demoToRun={demoToRun}
                    handleClose={handleCloseModal}
                />
            </BasicModal>
            {loading ? <Loader /> : null}
        </Container>
    );
};
