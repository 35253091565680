import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styled from 'styled-components/macro';
import { FormHelperText, SelectChangeEvent } from '@mui/material';
import { CreatedTemplate } from './types';
import { TypeSelect } from './TypeSelect';
import { Loader } from '../../components/Loader';
import { handleTemplateSubmit } from './utils';
import { ZoneNumberSelect } from './ZoneNumberSelect';
import { MediaSelect } from './MediaSelect';
import { ICreatedMedia } from '../media/types';
import { getMediaList } from '../../clients/mediaClient';
import { usePermissionCheck } from '../../redux/hooks';

const Container = styled.div`
    @media (max-width: 1023px) {
        width: 100vw;
    }
    width: 40vw;
    height: 100%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 16px;
    height: 100%;
`;
const ZoneContainer = styled.div`
    display: flex;
    gap: 8px;
`;
const Title = styled.div`
    display: flex;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.header3};
    line-height: 33px;
`;

const MediaTitle = styled.div`
    display: flex;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.header4};
    line-height: 25px;
    color: ${(props) => props.theme.colors.copyTextGray};
`;

const ButtonsContainer = styled.div`
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    gap: 8px;
`;

interface Props {
    templateToEdit: CreatedTemplate | undefined;
    canDelete: boolean;
    templatesList: CreatedTemplate[] | null | undefined;
    setTemplateToDelete: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
    handleClose: () => void;
}

export const AddTemplateModal: React.FC<Props> = ({
    templateToEdit,
    canDelete,
    templatesList,
    setTemplateToDelete,
    handleClose,
}) => {
    const [loading, setLoading] = useState(false);
    const [uniqueCombinationError, setUniqueCombinationError] = useState('');
    const [mediaList, setMediaList] = useState<ICreatedMedia[]>();
    const hasEditPermission = usePermissionCheck('templates.edit');

    const canEdit = !templateToEdit || (!!templateToEdit && hasEditPermission);

    const fetchMedia = async (search?: string) => {
        setLoading(true);
        const response = await getMediaList(search);
        setMediaList(response?.items);
        setLoading(false);
    };
    useEffect(() => {
        fetchMedia();
    }, []);
    const uniqueErrorText =
        'Zone + Zone Description shall be unique combination within Customer Type';
    const validationSchema = yup
        .object({
            type: yup
                .string()
                .required('Type is required')
                .max(50, 'Must be less then 50 symbols'),
            zoneNumber: yup
                .string()
                .required('Zone number is required')
                .matches(/^(0?[1-9]|\d{2})$/, 'Zone Number must be 1-99'),
            zoneDescription: yup
                .string()
                .required('Zone description is required')
                .max(50, 'Must be less then 50 symbols'),
            mediaId: yup
                .string()
                .max(50, 'Must be less then 50 symbols')
                .nullable(),
        })
        .test(
            'uniqueNumberAndDescription',
            uniqueErrorText,
            (value, { ...rest }) => {
                const combinationNotUnique = templatesList?.find(
                    (template) =>
                        template.zoneNumber === value.zoneNumber &&
                        template.zoneDescription === value.zoneDescription &&
                        template.type === value.type &&
                        template.zoneTemplateId !==
                            templateToEdit?.zoneTemplateId
                );
                if (combinationNotUnique) {
                    return rest.createError({
                        message: uniqueErrorText,
                    });
                }

                return true;
            }
        );

    const formik = useFormik({
        initialValues: {
            type: templateToEdit?.type ?? '',
            zoneNumber: templateToEdit?.zoneNumber ?? '',
            zoneDescription: templateToEdit?.zoneDescription ?? '',
            mediaId: templateToEdit?.media?.mediaId ?? '',
        },
        validate: (values) => {
            setUniqueCombinationError('');
            try {
                validationSchema.validateSync(values);
            } catch (error: any) {
                if (error.message === uniqueErrorText)
                    setUniqueCombinationError(error.message as string);
            }
        },
        validationSchema,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onSubmit,
    });
    async function onSubmit(values: any) {
        if (!uniqueCombinationError)
            handleTemplateSubmit(
                values,
                formik.initialValues,
                templateToEdit,
                canEdit,
                setLoading,
                handleClose
            );
    }
    const handleBlurWhenEdit = () => {
        if (templateToEdit) formik.handleSubmit();
    };
    const filteredMediaList = mediaList?.filter(
        (media) => media.customerType === formik.values.type
    );
    const handleTypeChange = (event: SelectChangeEvent<string>) => {
        formik.handleChange(event);
        formik.setFieldValue('mediaId', '');
    };

    return (
        <Container>
            <Form onSubmit={formik.handleSubmit}>
                <Title>
                    {templateToEdit ? 'Edit Template' : 'Create Template'}
                </Title>
                <ZoneContainer>
                    <ZoneNumberSelect
                        zoneNumber={String(formik.values.zoneNumber)}
                        handleChange={formik.handleChange}
                        onBlur={handleBlurWhenEdit}
                        error={
                            formik.touched.zoneNumber &&
                            Boolean(formik.errors.zoneNumber)
                        }
                        helperText={
                            formik.touched.zoneNumber &&
                            formik.errors.zoneNumber
                        }
                        disabled={!canEdit}
                    />
                    <TextField
                        fullWidth
                        multiline
                        id="zoneDescription"
                        name="zoneDescription"
                        label="Zone description"
                        inputProps={{ maxLength: 50 }}
                        value={formik.values.zoneDescription}
                        onChange={formik.handleChange}
                        onBlur={handleBlurWhenEdit}
                        error={
                            formik.touched.zoneDescription &&
                            Boolean(formik.errors.zoneDescription)
                        }
                        helperText={
                            formik.touched.zoneDescription &&
                            formik.errors.zoneDescription
                        }
                        sx={{ m: '16px 0' }}
                        disabled={!canEdit}
                    />
                </ZoneContainer>
                <TypeSelect
                    type={formik.values.type}
                    handleChange={handleTypeChange}
                    onBlur={handleBlurWhenEdit}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    sx={{ m: '16px 0', width: '100%' }}
                    disabled={!canEdit}
                />
                <MediaTitle>Media</MediaTitle>
                <MediaSelect
                    mediaList={filteredMediaList}
                    selectedMedia={formik.values.mediaId}
                    handleChange={formik.handleChange}
                    onBlur={handleBlurWhenEdit}
                    error={
                        formik.touched.mediaId && Boolean(formik.errors.mediaId)
                    }
                    none
                    helperText={formik.touched.mediaId && formik.errors.mediaId}
                    templateHasMedia={!!templateToEdit?.media}
                    sx={{ m: '16px 0', width: '100%' }}
                    disabled={!canEdit}
                />
                {!!uniqueCombinationError &&
                    (formik.touched.zoneDescription ||
                        formik.touched.zoneNumber) && (
                        <FormHelperText
                            error
                            id="rows-error"
                            sx={{ textAlign: 'center' }}
                        >
                            {uniqueCombinationError}
                        </FormHelperText>
                    )}
                {templateToEdit ? (
                    <ButtonsContainer>
                        <div>
                            <Button
                                variant="outlined"
                                name="archive"
                                onClick={setTemplateToDelete}
                                disabled={!canDelete}
                                sx={{ mr: '18px', width: '128px' }}
                            >
                                Delete
                            </Button>
                        </div>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            sx={{ width: '128px' }}
                        >
                            Close
                        </Button>
                    </ButtonsContainer>
                ) : (
                    <ButtonsContainer>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{ width: '128px' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            name="create"
                            type="submit"
                            sx={{ width: '128px' }}
                        >
                            Create zone
                        </Button>
                    </ButtonsContainer>
                )}
            </Form>
            {loading ? <Loader /> : null}
        </Container>
    );
};
