import React from 'react';
import { Button } from '@mui/material';
import {
    Container,
    Title,
    Message,
    ButtonsContainer,
} from '../pages/templates/ConfirmDeleteStyled';

interface Props {
    message: string;
    confirm: () => void;
    close: () => void;
}

export const ConfirmDeleteModal: React.FC<Props> = ({
    message,
    confirm,
    close,
}) => {
    return (
        <Container>
            <Title>
                <span>Confirm Removal</span>
            </Title>
            <Message>{message}</Message>
            <ButtonsContainer>
                <Button variant="outlined" onClick={close}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        confirm();
                        close();
                    }}
                >
                    Delete
                </Button>
            </ButtonsContainer>
        </Container>
    );
};
