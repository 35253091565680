import { createTemplate, updateTemplate } from '../../clients/templatesClient';
import { CreatedTemplate, CustomerType, Template } from './types';

export function getModifiedValues(
    values: Partial<CreatedTemplate>,
    initialValues: {
        type: CustomerType.Smb | CustomerType.Residential;
        zoneNumber: string;
        zoneDescription: string;
        media: string | null;
    }
) {
    const modifiedValues = {} as any;

    if (values) {
        Object.entries(values).forEach(([key, value]) => {
            // @ts-ignore
            if (value !== initialValues[key]) {
                modifiedValues[key] = value;
            }
        });
    }

    return modifiedValues;
}

export const handleTemplateSubmit = async (
    values: any,
    initialValues: any,
    templateToEdit: CreatedTemplate | undefined,
    canEdit: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    handleClose: () => void
) => {
    const modifiedValues = getModifiedValues(values, initialValues);
    const modifiedValuesEmpty =
        Object.keys(modifiedValues).length === 0 &&
        modifiedValues.constructor === Object;
    if (templateToEdit && modifiedValuesEmpty) return;
    if (templateToEdit && canEdit) {
        setLoading(true);
        await updateTemplate({
            zoneTemplateId: templateToEdit.zoneTemplateId,
            data: modifiedValues,
        });
        setLoading(false);
    } else {
        setLoading(true);
        await createTemplate(values as Template);
        setLoading(false);
        handleClose();
    }
};
