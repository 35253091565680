import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
    Checkbox,
    SelectChangeEvent,
    FormGroup,
    FormControlLabel,
} from '@mui/material';
import { ICreatedDemo } from './types';
import { CreatedTemplate } from '../templates/types';
import {
    ButtonsContainer,
    ContactsHeader,
    Container,
    DropdownsContainer,
    Form,
    Header,
    ParticipantRow,
    SelectAll,
    ZoneContainer,
} from './RunIncidentModalStyled';
import { ZoneTemplateSelect } from './ZoneTemplateSelect';
import { ZoneNumberSelect } from '../templates/ZoneNumberSelect';
import { useAppSelector, usePermissionCheck } from '../../redux/hooks';
import { selectProfile } from '../../redux/userProfileSlice';
import { createIncident } from '../../clients/incidentClient';
import { Loader } from '../../components/Loader';

const validationSchema = yup.object({
    customerName: yup
        .string()
        .required('Customer name is required')
        .matches(/^[\w\s#.,'-]+$/, 'Invalid name format')
        .max(50, 'Must be less then 50 symbols'),
    customerAddress: yup
        .string()
        .required('Customer address is required')
        .matches(/^[\w\s#.,'-]+$/, 'Invalid address format')
        .max(100, 'Must be less then 100 symbols'),
    zoneNumber: yup
        .string()
        .required('Zone number is required')
        .max(3, 'Must be less then 3 symbols'),
    zoneDescription: yup
        .string()
        .required('Zone description is required')
        .max(50, 'Must be less then 50 symbols'),
});

interface Props {
    demoToRun: ICreatedDemo | undefined;
    templates: CreatedTemplate[] | null;
    handleClose: () => void;
}

export const RunIncidentModal: React.FC<Props> = ({
    demoToRun,
    templates,
    handleClose,
}) => {
    const canRun = usePermissionCheck('demos.run');
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(
        Number(demoToRun?.incidentsRun) <= 0
    );

    const { profile } = useAppSelector(selectProfile);
    const participant0Filled =
        !!demoToRun?.participant0.name && !!demoToRun?.participant0.phone;
    const participant1Filled =
        !!demoToRun?.participant1.name && !!demoToRun?.participant1.phone;
    const participant2Filled =
        !!demoToRun?.participant2.name && !!demoToRun?.participant2.phone;
    const participant3Filled =
        !!demoToRun?.participant3.name && !!demoToRun?.participant3.phone;

    const formik = useFormik({
        initialValues: {
            zoneTemplateId: '',
            demoId: demoToRun?.demoId,
            mediaId: '',
            customerName: demoToRun?.customerName || '',
            customerAddress: demoToRun?.customerAddress || '',
            zoneNumber: '',
            zoneDescription: '',
            avs01: true,
            participant0:
                (demoToRun?.participant0.selected && participant0Filled) ||
                (selectAll && participant0Filled),
            participant1:
                (demoToRun?.participant1.selected && participant1Filled) ||
                (selectAll && participant1Filled),
            participant2:
                (demoToRun?.participant2.selected && participant2Filled) ||
                (selectAll && participant2Filled),
            participant3:
                (demoToRun?.participant3.selected && participant3Filled) ||
                (selectAll && participant3Filled),
        },
        validationSchema,
        onSubmit: async (values: any) => {
            setLoading(true);
            await createIncident(values);
            setLoading(false);
            handleClose();
        },
    });

    useEffect(() => {
        const filledParticipants = [
            participant0Filled,
            participant1Filled,
            participant2Filled,
            participant3Filled,
        ];
        const checkedParticipants = [
            formik.values.participant0,
            formik.values.participant1,
            formik.values.participant2,
            formik.values.participant3,
        ];

        const allFilledChecked = filledParticipants.every(
            (filled, index) => !filled || checkedParticipants[index]
        );

        setSelectAll(allFilledChecked);
    }, [
        formik.values.participant0,
        formik.values.participant1,
        formik.values.participant2,
        formik.values.participant3,
        participant0Filled,
        participant1Filled,
        participant2Filled,
        participant3Filled,
    ]);

    const handleZoneTemplateChange = async (
        event: SelectChangeEvent<string>
    ) => {
        formik.setFieldValue('zoneTemplateId', event.target.value);
        formik.setFieldValue(
            'zoneNumber',
            templates?.find(
                (template) => template.zoneTemplateId === event.target.value
            )?.zoneNumber
        );
        formik.setFieldTouched('zoneNumber', true);
        await formik.validateField('zoneNumber');
        formik.setFieldValue(
            'zoneDescription',
            templates?.find(
                (template) => template.zoneTemplateId === event.target.value
            )?.zoneDescription
        );
        formik.setFieldTouched('zoneDescription', true);
        await formik.validateField('zoneDescription');
        formik.setFieldValue(
            'mediaId',
            templates?.find(
                (template) => template.zoneTemplateId === event.target.value
            )?.media?.mediaId
        );
    };
    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        if (participant0Filled) formik.setFieldValue('participant0', isChecked);
        if (participant1Filled) formik.setFieldValue('participant1', isChecked);
        if (participant2Filled) formik.setFieldValue('participant2', isChecked);
        if (participant3Filled) formik.setFieldValue('participant3', isChecked);
    };

    const filteredTemplates = templates?.filter(
        (template) => template.type === demoToRun?.customerType
    );
    const salesPhone = profile?.phone ? profile?.phone : '';
    const salesEmail = profile?.email ? profile?.email : '';
    const salesInfo = `${
        !!salesPhone && !!salesEmail ? `${salesPhone} / ` : salesPhone
    }${salesEmail}`;

    const onCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e);
    };

    return (
        <Container>
            <Header>Start Incident</Header>
            <Form onSubmit={formik.handleSubmit}>
                <DropdownsContainer>
                    <ZoneTemplateSelect
                        templates={filteredTemplates}
                        selectedTemplate={formik.values.zoneTemplateId}
                        handleChange={handleZoneTemplateChange}
                        error={
                            formik.touched.zoneTemplateId &&
                            Boolean(formik.errors.zoneTemplateId)
                        }
                        helperText={
                            formik.touched.zoneTemplateId &&
                            formik.errors.zoneTemplateId
                        }
                        sx={{ m: '16px 0', width: '100%' }}
                    />
                </DropdownsContainer>
                <ParticipantRow>
                    <TextField
                        fullWidth
                        id="customerName"
                        label="Customer name"
                        value={formik.values.customerName}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.customerName &&
                            Boolean(formik.errors.customerName)
                        }
                        helperText={
                            formik.touched.customerName &&
                            formik.errors.customerName
                        }
                        sx={{ m: '16px 8px 16px 0' }}
                    />
                    <TextField
                        fullWidth
                        multiline
                        id="customerAddress"
                        label="Customer address"
                        value={formik.values.customerAddress}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.customerAddress &&
                            Boolean(formik.errors.customerAddress)
                        }
                        helperText={
                            formik.touched.customerAddress &&
                            formik.errors.customerAddress
                        }
                        sx={{ m: '16px 0' }}
                    />
                </ParticipantRow>
                <ZoneContainer>
                    <ZoneNumberSelect
                        zoneNumber={formik.values.zoneNumber}
                        handleChange={formik.handleChange}
                        error={
                            formik.touched.zoneNumber &&
                            Boolean(formik.errors.zoneNumber)
                        }
                        helperText={
                            formik.touched.zoneNumber &&
                            formik.errors.zoneNumber
                        }
                    />
                    <TextField
                        fullWidth
                        multiline
                        id="zoneDescription"
                        name="zoneDescription"
                        label="Zone description"
                        value={formik.values.zoneDescription}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.zoneDescription &&
                            Boolean(formik.errors.zoneDescription)
                        }
                        helperText={
                            formik.touched.zoneDescription &&
                            formik.errors.zoneDescription
                        }
                        sx={{ m: '16px 0' }}
                    />
                </ZoneContainer>
                <ContactsHeader>
                    <SelectAll>Contacts</SelectAll>
                    <FormGroup
                        row
                        sx={{
                            marginLeft: 2,
                            justifyContent: 'space-between',
                            flexGrow: 1,
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            }
                            label="Select All"
                        />
                    </FormGroup>
                </ContactsHeader>
                {profile?.phone ? (
                    <ParticipantRow>
                        <Checkbox
                            checked
                            disabled
                            id="salesRep"
                            name="salesRep"
                        />
                        <TextField
                            variant="standard"
                            id="salesRep.name"
                            name="salesRep.name"
                            label="Name"
                            value={profile?.firstName}
                            onChange={formik.handleChange}
                            sx={{ m: '16px 8px 16px 0' }}
                            disabled
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            id="salesRep.info"
                            name="salesRep.info"
                            label="Contact info"
                            value={salesInfo}
                            onChange={formik.handleChange}
                            sx={{ m: '16px 8px 16px 0' }}
                            disabled
                        />
                    </ParticipantRow>
                ) : null}
                <ParticipantRow>
                    <Checkbox
                        checked={formik.values.participant0}
                        onChange={onCheckboxClick}
                        id="participant0"
                        name="participant0"
                        disabled={!participant0Filled}
                    />
                    <TextField
                        variant="standard"
                        fullWidth
                        id="participant0.name"
                        name="participant0.name"
                        label="Name"
                        value={demoToRun?.participant0.name}
                        onChange={formik.handleChange}
                        sx={{ m: '16px 8px 16px 0' }}
                        disabled
                    />
                    <TextField
                        variant="standard"
                        fullWidth
                        id="participant0.phone"
                        name="participant0.phone"
                        label="Phone"
                        type="tel"
                        value={demoToRun?.participant0.phone}
                        onChange={formik.handleChange}
                        sx={{ m: '16px 0' }}
                        disabled
                    />
                </ParticipantRow>
                <ParticipantRow>
                    <Checkbox
                        checked={formik.values.participant1}
                        onChange={onCheckboxClick}
                        id="participant1"
                        name="participant1"
                        disabled={!participant1Filled}
                    />
                    <TextField
                        variant="standard"
                        fullWidth
                        id="participant1.name"
                        name="participant1.name"
                        label="Name"
                        value={demoToRun?.participant1.name}
                        onChange={formik.handleChange}
                        sx={{ m: '16px 8px 16px 0' }}
                        disabled
                    />
                    <TextField
                        variant="standard"
                        fullWidth
                        id="participant1.phone"
                        name="participant1.phone"
                        label="Phone"
                        type="tel"
                        value={demoToRun?.participant1.phone}
                        onChange={formik.handleChange}
                        sx={{ m: '16px 0' }}
                        disabled
                    />
                </ParticipantRow>
                <ParticipantRow>
                    <Checkbox
                        checked={formik.values.participant2}
                        onChange={onCheckboxClick}
                        id="participant2"
                        name="participant2"
                        disabled={!participant2Filled}
                    />
                    <TextField
                        variant="standard"
                        fullWidth
                        id="participant2.name"
                        name="participant2.name"
                        label="Name"
                        value={demoToRun?.participant2.name}
                        onChange={formik.handleChange}
                        sx={{ m: '16px 8px 16px 0' }}
                        disabled
                    />
                    <TextField
                        variant="standard"
                        fullWidth
                        id="participant2.phone"
                        name="participant2.phone"
                        label="Phone"
                        type="tel"
                        value={demoToRun?.participant2.phone}
                        onChange={formik.handleChange}
                        sx={{ m: '16px 0' }}
                        disabled
                    />
                </ParticipantRow>
                <ParticipantRow>
                    <Checkbox
                        checked={formik.values.participant3}
                        onChange={onCheckboxClick}
                        id="participant3"
                        name="participant3"
                        disabled={!participant3Filled}
                    />
                    <TextField
                        variant="standard"
                        fullWidth
                        id="participant3.name"
                        name="participant3.name"
                        label="Name"
                        value={demoToRun?.participant3.name}
                        onChange={formik.handleChange}
                        sx={{ m: '16px 8px 16px 0' }}
                        disabled
                    />
                    <TextField
                        variant="standard"
                        fullWidth
                        id="participant3.phone"
                        name="participant3.phone"
                        label="Phone"
                        type="tel"
                        value={demoToRun?.participant3.phone}
                        onChange={formik.handleChange}
                        sx={{ m: '16px 0' }}
                        disabled
                    />
                </ParticipantRow>
                <ButtonsContainer>
                    <Button
                        variant="outlined"
                        name="cancel"
                        onClick={handleClose}
                        sx={{ width: '130px' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        name="submit"
                        type="submit"
                        disabled={!canRun}
                        sx={{ width: '130px' }}
                    >
                        Run Incident
                    </Button>
                </ButtonsContainer>
            </Form>
            {loading ? <Loader /> : null}
        </Container>
    );
};
