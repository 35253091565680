import { InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

interface Props {
    placeholder: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sx?: SxProps<Theme>;
}

export const SearchBar: React.FC<Props> = ({
    placeholder,
    sx,
    handleChange,
}) => {
    return (
        <TextField
            id="searchbar"
            name="searchbar"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                type: 'search',
            }}
            onChange={handleChange}
            placeholder={placeholder}
            fullWidth
            sx={sx}
        />
    );
};
