import React from 'react';
import FormControl from '@mui/material/FormControl';
import {
    Select,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    SxProps,
    Theme,
    SelectChangeEvent,
} from '@mui/material';
import { CustomerType } from './types';

interface Props {
    type: CustomerType.Smb | CustomerType.Residential | string;
    error: boolean | undefined;
    helperText: string | false | undefined;
    none?: boolean;
    inputName?: string;
    sx: SxProps<Theme>;
    disabled?: boolean;
    onBlur?: () => void;
    handleChange: (event: SelectChangeEvent<string>) => void;
}
export const TypeSelect: React.FC<Props> = ({
    type,
    error,
    helperText,
    inputName,
    none,
    sx,
    disabled,
    onBlur,
    handleChange,
}) => {
    const labelClass = error ? 'Mui-error' : '';
    return (
        <FormControl sx={sx}>
            <InputLabel className={labelClass} id="type-label">
                Customer Type
            </InputLabel>
            <Select
                name={inputName || 'type'}
                id={inputName || 'type'}
                labelId="type-label"
                onChange={handleChange}
                onBlur={onBlur}
                variant="standard"
                value={type}
                input={<OutlinedInput label="Customer Type" error={error} />}
                disabled={disabled}
            >
                {none ? (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                ) : null}
                <MenuItem value={CustomerType.Residential}>
                    <span>Residential</span>
                </MenuItem>
                <MenuItem value={CustomerType.Smb}>
                    <span>SMB</span>
                </MenuItem>
            </Select>
            {error && (
                <FormHelperText error id="role-error">
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};
