import { toast } from 'react-toastify';
import { config } from '../config';
import { GetDemosResponse, ICreatedDemo, IDemo } from '../pages/demos/types';
import { get, patch, post } from './axiosService';

export const getDemos = async (search?: string, page?: string) => {
    try {
        const response = await get<GetDemosResponse>(
            `${config.clients.demosClient.baseUrl}list`,
            { search, page }
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const getDemo = async ({ id }: { id: string }) => {
    try {
        const response = await get<ICreatedDemo>(
            `${config.clients.demosClient.baseUrl}${id}`
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const createDemo = async (data: IDemo): Promise<any> => {
    try {
        const response = await post(config.clients.demosClient.baseUrl, data);

        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const updateDemo = async (
    id: string,
    data: Partial<IDemo>
): Promise<any> => {
    try {
        const response = await patch(
            `${config.clients.demosClient.baseUrl}${id}`,
            data
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};
