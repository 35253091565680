export const config = {
    clients: {
        templatesClient: {
            baseUrl: `${process.env.REACT_APP_API_URL}zoneTemplate/`,
        },
        templateClient: {
            baseUrl: `${process.env.REACT_APP_API_URL}template/`,
        },
        profileClient: {
            baseUrl: `${process.env.REACT_APP_API_URL}profile/`,
        },
        reportsClient: {
            baseUrl: `${process.env.REACT_APP_API_URL}reports/`,
        },
        demosClient: {
            baseUrl: `${process.env.REACT_APP_API_URL}demo/`,
        },
        incidentClient: {
            baseUrl: `${process.env.REACT_APP_API_URL}incident/`,
        },
        mediaClient: {
            baseUrl: `${process.env.REACT_APP_API_URL}media/`,
        },
    },
};
