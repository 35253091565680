import styled from 'styled-components/macro';

const Wrapper = styled.div`
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 10fr;
    @media (max-width: 1023px) {
        grid-template-columns: 1fr;
    }
    overflow: auto;
`;
const NavWrapper = styled.div`
    height: 100%;
    z-index: 2;
    background-color: ${(props) => props.theme.colors.ADTBlue};
    @media (max-width: 1023px) {
        position: absolute;
        height: 66px;
        width: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: unset;
        margin: 6px;
        border: 1px solid #8e9099;
        border-radius: 24px;
        z-index: 100;
    }
`;
const ContentWrapper = styled.div`
    padding: 56px 76px;
    height: 100vh;
    overflow: auto;
    @media (max-width: 1023px) {
        padding: 0;
    }
`;
const NoRoute = styled.div`
    padding: 1rem;
`;
export { Wrapper, NavWrapper, ContentWrapper, NoRoute };
