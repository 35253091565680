import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { selectProfile } from './userProfileSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function permissionCheck(
    userPermissions: string[] | undefined,
    permission: string
): boolean {
    const hasExact = (array: string[], string: string): boolean => {
        return array.includes(string);
    };

    const hasAll = (array: string[], string: string): boolean => {
        let checkValue = string;
        const parts = string.split('.');
        if (parts.length > 1) {
            parts.pop();
            checkValue = `${parts?.join('.')}.all`;
        }
        return array.includes(checkValue);
    };

    if (userPermissions && userPermissions.length > 0) {
        return (
            hasExact(userPermissions, permission) ||
            hasAll(userPermissions, permission)
        );
    }
    return false;
}
export function usePermissionCheck(permission: string): boolean {
    const user = useAppSelector(selectProfile);
    const userPermission = user?.profile?.permissions;
    return permissionCheck(userPermission, permission);
}
