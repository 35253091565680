import styled from 'styled-components/macro';

const DragndropInput = styled.input``;

const Dragndrop = styled.div<{ error: boolean }>`
    display: flex;
    height: 50px;
    width: 100%;
    color: ${(props) =>
        props.error ? props.theme.colors.errorRed : props.theme.colors.ADTBlue};
    border: 1px solid
        ${(props) =>
            props.error
                ? props.theme.colors.errorRed
                : props.theme.colors.elementGray2};
    border-radius: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    white-space: nowrap;
    align-items: center;
    margin: 16px 0;
    padding: 8px;
    overflow: hidden;
`;

const Thumb = styled.aside`
    display: inline-flex;
    border-radius: 2px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 150px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
`;
const ThumbInner = styled.div`
    display: flex;
    min-width: 0;
    overflow: hidden;
    border: 1px solid ${(props) => props.theme.colors.iceGrey};
    margin-bottom: 8px;
`;

const VideoPreview = styled.video`
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
`;
const InputMessage = styled.label<{ disabled: boolean }>`
    color: ${(props) =>
        props.disabled ? props.theme.colors.elementGray2 : 'inherit'};
`;

export {
    DragndropInput,
    Dragndrop,
    Thumb,
    ThumbInner,
    VideoPreview,
    InputMessage,
};
