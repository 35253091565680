import * as React from 'react';
import Modal from '@mui/material/Modal';
import styled from 'styled-components/macro';
import { defaultTheme } from '../shared/styles/theme';

const InnerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 10px;
    padding: 16px;
    @media (max-width: 1023px) {
        height: 100%;
        overflow: auto;
    }
`;

interface ModalProps {
    open: boolean;
    children: React.ReactNode;
    handleClose: (event: any, reason: string) => void;
}

const BasicModal: React.FC<ModalProps> = ({ children, open, handleClose }) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                sx={{
                    '.MuiBox-root': {
                        backgroundColor: defaultTheme.colors.greyBG,
                        p: 0,
                        borderRadius: '10px',
                    },
                }}
            >
                <InnerWrapper data-testid="inner-wrapper">
                    {children}
                </InnerWrapper>
            </Modal>
        </div>
    );
};
export default BasicModal;
