import TextField from '@mui/material/TextField';
import React from 'react';
import styled from 'styled-components/macro';

interface Props {
    id: string;
    name: string;
    label: string;
    value: string;
    error: boolean | undefined;
    helperText: string | false | undefined;
    sx?: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: () => void;
    onFocus: () => void;
    onRemoveClick: () => void;
}
const PhoneContainer = styled.div`
    display: flex;
    align-items: center;
`;
const Remove = styled.div`
    margin-left: 16px;
    font-weight: 400;
    font-size: ${(props) => props.theme.fonts.text};
    line-height: 16px;
    color: ${(props) => props.theme.colors.ADTBlue};
    cursor: pointer;
`;
export const PhoneInput: React.FC<Props> = ({
    id,
    name,
    label,
    value,
    error,
    helperText,
    sx,
    onChange,
    onBlur,
    onFocus,
    onRemoveClick,
}) => {
    return (
        <PhoneContainer>
            <TextField
                fullWidth
                id={id}
                name={name}
                label={label}
                type="tel"
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
                sx={sx}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            {value ? <Remove onClick={onRemoveClick}>Remove</Remove> : null}
        </PhoneContainer>
    );
};
