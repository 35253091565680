import React, { useCallback, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Card } from './Card';
import BasicModal from '../../components/BasicModal';
import { AddTemplateModal } from './AddTemplateModal';
import { deleteTemplate, getTemplates } from '../../clients/templatesClient';
import { CreatedTemplate, CustomerType } from './types';
import { Loader } from '../../components/Loader';
import { ConfirmDeleteModal } from '../../components/ConfirmDeleteModal';
import { usePermissionCheck } from '../../redux/hooks';
import { SearchBar } from '../../components/SearchBar';
import { TypeSelect } from './TypeSelect';
import { CardsContainer, Container } from './TemplatesStyled';
import { Header } from '../../components/Header';

export const Templates: React.FC = () => {
    const isTabletOrMobile = useMediaQuery('(max-width: 1023px)');
    const canDelete = usePermissionCheck('templates.delete');
    const canCreate = usePermissionCheck('templates.create');
    const [templatesList, setTemplates] = useState<CreatedTemplate[] | null>();
    const [openAddTemplateModal, setOpenAddTemplateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [templateToEdit, setTemplateToEdit] = useState<CreatedTemplate>();
    const [templateToDelete, setTemplateToDelete] = useState<string>();
    const [filterByType, setFilterByType] = useState<'' | CustomerType>('');
    const handleFilterByType = (event: SelectChangeEvent) => {
        setFilterByType(event.target.value as '' | CustomerType);
    };

    const fetchTemplates = useCallback(async () => {
        setLoading(true);
        const response = await getTemplates(search, filterByType);
        if (response) setTemplates(response.items);
        setLoading(false);
    }, [search, filterByType]);
    const onModalClose = (): void => {
        setOpenAddTemplateModal(false);
        setTemplateToEdit(undefined);
        setTemplateToDelete(undefined);
        fetchTemplates();
    };
    const handleOpen = (): void => setOpenAddTemplateModal(true);
    const handleClose = (): void => {
        onModalClose();
    };
    const onTemplateDelete = async () => {
        setLoading(true);
        if (templateToDelete) await deleteTemplate(templateToDelete);
        setLoading(false);
        onModalClose();
    };
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 2 || event.target.value === '')
            setSearch(event.target.value);
    };

    useEffect(() => {
        fetchTemplates();
    }, [search, filterByType, fetchTemplates]);

    return (
        <Container>
            <Header
                isTabletOrMobile={isTabletOrMobile}
                pageName="Templates"
                handleAddPress={handleOpen}
                addButtonContent="Create Template"
                canCreate={canCreate}
            >
                <SearchBar
                    handleChange={handleSearch}
                    placeholder="Search..."
                />
                <TypeSelect
                    type={filterByType}
                    handleChange={handleFilterByType}
                    error={false}
                    helperText=""
                    none
                    sx={{
                        m: isTabletOrMobile ? '16px 0' : '16px 0 16px 8px',
                        width: isTabletOrMobile ? '100%' : '40%',
                    }}
                />
            </Header>
            <CardsContainer>
                {templatesList?.map((template) => (
                    <Card
                        template={template}
                        key={template.zoneTemplateId}
                        setTemplateToEdit={() => setTemplateToEdit(template)}
                    />
                ))}
            </CardsContainer>
            <BasicModal
                open={openAddTemplateModal || !!templateToEdit}
                handleClose={handleClose}
            >
                <AddTemplateModal
                    handleClose={onModalClose}
                    templateToEdit={templateToEdit}
                    setTemplateToDelete={(
                        e: React.SyntheticEvent<HTMLButtonElement>
                    ) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setTemplateToDelete(templateToEdit?.zoneTemplateId);
                    }}
                    canDelete={canDelete}
                    templatesList={templatesList}
                />
            </BasicModal>
            <BasicModal open={!!templateToDelete} handleClose={handleClose}>
                <ConfirmDeleteModal
                    message="Are you sure you want to delete this template?"
                    close={onModalClose}
                    confirm={onTemplateDelete}
                />
            </BasicModal>
            {loading ? <Loader /> : null}
        </Container>
    );
};
