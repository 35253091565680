import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { updateProfile } from '../../clients/profileClient';
import { UserProfileState, fetchUser } from '../../redux/userProfileSlice';
import { UserProfile } from './types';

export function getModifiedValues(
    values: Partial<UserProfile>,
    initialValues: UserProfile
): Partial<UserProfile> | null {
    const modifiedValues: Partial<UserProfile> = {};

    Object.keys(values).forEach((key) => {
        const valueKey = key as keyof UserProfile;
        if (
            JSON.stringify(values[valueKey]) !==
            JSON.stringify(initialValues[valueKey])
        ) {
            modifiedValues[valueKey] = values[valueKey] as any;
        }
    });
    const modifiedValuesEmpty =
        Object.keys(modifiedValues).length === 0 &&
        modifiedValues.constructor === Object;
    if (modifiedValuesEmpty) return null;
    return modifiedValues;
}
export const onProfileSubmit = async (
    values: Partial<UserProfile>,
    initialValues: UserProfile,
    dispatch: ThunkDispatch<
        {
            userProfile: UserProfileState;
        },
        undefined,
        AnyAction
    > &
        Dispatch<AnyAction>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const modifiedValues = getModifiedValues(values, initialValues);
    if (modifiedValues) {
        setLoading(true);
        await updateProfile(modifiedValues);
        await dispatch(fetchUser());
        setLoading(false);
    }
};
