import { createMedia, updateMedia } from '../../clients/mediaClient';
import { ICreatedMedia, IMedia } from './types';

export function getModifiedValues(
    values: IMedia,
    initialValues: IMedia
): Partial<IMedia> {
    const modifiedValues: Partial<IMedia> = {};

    Object.keys(values).forEach((key) => {
        const valueKey = key as keyof IMedia;
        if (
            JSON.stringify(values[valueKey]) !==
            JSON.stringify(initialValues[valueKey])
        ) {
            modifiedValues[valueKey] = values[valueKey] as any;
        }
    });

    return modifiedValues;
}

export const handleMediaSubmit = async (
    values: any,
    mediaToEdit: ICreatedMedia | undefined,
    initialValues: any,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    handleClose: () => void
) => {
    if (mediaToEdit) {
        const modifiedValues = getModifiedValues(values, initialValues);
        const modifiedValuesEmpty =
            Object.keys(modifiedValues).length === 0 &&
            modifiedValues.constructor === Object;
        if (mediaToEdit && modifiedValuesEmpty) return;
        setLoading(true);
        await updateMedia({
            mediaId: mediaToEdit.mediaId,
            // @ts-ignore
            data: modifiedValues,
        });
        setLoading(false);
    } else {
        setLoading(true);
        await createMedia(values as IMedia);
        setLoading(false);
        handleClose();
    }
};

export const isMediaDescriptionUnique = (
    value: string | undefined,
    mediaList: ICreatedMedia[] | undefined,
    mediaToEdit: ICreatedMedia | undefined
) => {
    const combinationNotUnique = mediaList?.find(
        (media) =>
            media.mediaDescription === value &&
            media.mediaId !== mediaToEdit?.mediaId
    );
    if (combinationNotUnique) {
        return false;
    }

    return true;
};
