import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
// AM-1019 Commented out
// import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import { Templates } from './pages/templates/Templates';
// AM-1019 Commented out
// import { Reports } from './pages/reports/Reports';
import { Demos } from './pages/demos/Demos';
import { Media } from './pages/media/Media';

export const routes = [
    {
        label: 'Demos',
        path: '/',
        component: Demos,
        icon: ContactPhoneOutlinedIcon,
        permission: 'demos.view',
    },
    {
        label: 'Templates',
        path: 'templates',
        component: Templates,
        icon: ArticleOutlinedIcon,
        permission: 'templates.view',
    },
    {
        label: 'Media',
        path: 'media',
        component: Media,
        icon: OndemandVideoIcon,
        permission: 'media.view',
    },

    // AM-1019 To comment out this section since it's not currently used
    // {
    //     label: 'Reports',
    //     path: 'reports',
    //     component: Reports,
    //     icon: InsightsOutlinedIcon,
    //     permission: 'reports.view',
    // },
];
