import React from 'react';
import Button from '@mui/material/Button';
import {
    SubTitle,
    ButtonsContainer,
    Container,
} from './CreateDemoConfirmModalStyled';

interface Props {
    handleClose: () => void;
    handleCloseSubmodal: () => void;
}

export const CreateDemoConfirmModal: React.FC<Props> = ({
    handleClose,
    handleCloseSubmodal,
}) => {
    return (
        <Container>
            <SubTitle>
                Are you sure you want to cancel? Unsaved demo information will
                be lost.
            </SubTitle>
            <ButtonsContainer>
                <Button
                    variant="outlined"
                    onClick={handleCloseSubmodal}
                    name="No"
                    sx={{ width: '128px' }}
                >
                    No
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    name="Yes"
                    onClick={handleClose}
                    sx={{ width: '128px' }}
                >
                    Yes
                </Button>
            </ButtonsContainer>
        </Container>
    );
};
