import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
    colors: {
        grey1: '#5e6366',
        backgroundGray: '#f5f6f7',
        grey4: '#d4d9dd',
        grey5: '#e7eaec',
        grey6: '#a1a1a1',
        greyBG: '#f3f5f6',
        iceGrey: '#ededed',
        primaryBlack: '#242629',
        copyTextGray: '#46494b',
        elementGray1: '#aeb8bf',
        elementGray2: '#919699',
        ctaBlue: '#1775ab',
        moonBlue: '#007edd',
        ADTBlue: '#0061aa',
        midnightBlue: '#102955',
        green: '#38d5b5',
        coral: '#ff6853',
        chestnut: '#e2aa5d',
        alertOrange: '#ff9d2b',
        promoCartRed: '#e91e3c',
        errorRed: '#df0000',
        white: '#ffffff',
        black: '#000',
    },
    fonts: {
        subtext: '10px',
        text: '12px',
        subHeading: '14px',
        header4: '18px',
        header3: '24px',
        header2: '32px',
        header1: '48px',
    },
};
