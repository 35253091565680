import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ExplicitOutlinedIcon from '@mui/icons-material/ExplicitOutlined';
import { FormHelperText } from '@mui/material';
import { DeliveryType, DemoType, ICreatedDemo } from './types';
import { CustomerType } from '../templates/types';
import { TypeSelect } from '../templates/TypeSelect';
import { DemoTypeSelect } from './DemoTypeSelect';
import { DeliveryTypeSelect } from './DeliveryTypeSelect';
import {
    ButtonsContainer,
    Container,
    DropdownsContainer,
    Form,
    Header,
    ParticipantRow,
    SubTitle,
    Title,
    Type,
} from './CreateDemoModalStyled';
import { defaultTheme } from '../../shared/styles/theme';
import { handleDemoSubmit } from './utils';
import { Loader } from '../../components/Loader';
import { usePermissionCheck } from '../../redux/hooks';

const participantSchema = yup.object({
    name: yup
        .string()
        .max(50, 'Must be less than 50 characters')
        .matches(/^[\w\s#.,'\-\u2019]+$/, 'Invalid name format')
        .test('name', 'Please enter contact name', (value, { parent }) => {
            if (parent.phone && !value) {
                return false;
            }
            return true;
        }),
    phone: yup
        .string()
        .test('phone', 'Valid Phone Number Required', (value, { parent }) => {
            if (parent.name && !value) {
                return false;
            }
            if (value?.startsWith('****')) {
                return true;
            }
            if (value) {
                const phoneNumber = parsePhoneNumber(value, 'US');
                if (phoneNumber?.country !== 'US') {
                    return false;
                }
                return !!phoneNumber?.isValid();
            }
            return true;
        }),
});

const validationSchema = yup
    .object({
        customerName: yup
            .string()
            .required('Customer name is required')
            .matches(/^[\w\s#.,'\-\u2019]+$/, 'Invalid name format')
            .max(50, 'Must be less than 50 characters'),
        customerType: yup
            .string()
            .required('Customer type is required')
            .max(50, 'Must be less than 50 chracters'),
        customerAddress: yup
            .string()
            .required('Customer address is required')
            .matches(/^[\w\s#.,'\-\u2019]+$/, 'Invalid address format')
            .max(100, 'Must be less than 100 characters'),
        deliveryType: yup
            .string()
            .required('Delivery type is required')
            .max(50, 'Must be less than 50 characters'),
        demoType: yup
            .string()
            .required('Demo type is required')
            .max(50, 'Must be less than 50 characters'),
        participant0: participantSchema,
        participant1: participantSchema,
        participant2: participantSchema,
        participant3: participantSchema,
    })
    .test(
        'hasParticipantNameAndPhone',
        'At least one participant must have both name and phone filled in',
        (value, { ...rest }) => {
            const participants = [
                value.participant0,
                value.participant1,
                value.participant2,
                value.participant3,
            ];
            const hasNameAndPhone = participants.some(
                (participant) => participant.name && participant.phone
            );

            if (!hasNameAndPhone) {
                return rest.createError({
                    message:
                        'At least one participant must have both name and phone filled in',
                });
            }

            return true;
        }
    );

interface Props {
    demoToEdit: ICreatedDemo | undefined;
    setDemoToRun: React.Dispatch<
        React.SetStateAction<ICreatedDemo | undefined>
    >;
    handleClose: () => void;
    handleSave: () => void;
}

export const CreateDemoModal: React.FC<Props> = ({
    demoToEdit,
    setDemoToRun,
    handleClose,
    handleSave,
}) => {
    const [loading, setLoading] = useState(false);
    const [rowsError, setRowsError] = useState('');
    const hasEditPermission = usePermissionCheck('demos.edit');
    const canEdit =
        !demoToEdit ||
        (!!demoToEdit && hasEditPermission && !demoToEdit.isArchived);

    const formik = useFormik({
        initialValues: {
            customerType: demoToEdit?.customerType ?? '',
            demoType: demoToEdit?.demoType ?? '',
            deliveryType: demoToEdit?.deliveryType ?? '',
            customerName: demoToEdit?.customerName ?? '',
            customerAddress: demoToEdit?.customerAddress ?? '',
            participant0: demoToEdit?.participant0 ?? { name: '', phone: '' },
            participant1: demoToEdit?.participant1 ?? { name: '', phone: '' },
            participant2: demoToEdit?.participant2 ?? { name: '', phone: '' },
            participant3: demoToEdit?.participant3 ?? { name: '', phone: '' },
        },
        validate: (values) => {
            setRowsError('');
            try {
                validationSchema.validateSync(values);
            } catch (error: any) {
                if (
                    error.message ===
                    'At least one participant must have both name and phone filled in'
                )
                    setRowsError(error.message as string);
            }
        },
        validationSchema,
        validateOnBlur: !!demoToEdit,
        validateOnChange: false,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onSubmit,
    });
    async function onSubmit(values: any) {
        if (!rowsError)
            handleDemoSubmit(
                values,
                demoToEdit,
                formik.initialValues,
                setLoading,
                formik.setValues,
                handleSave
            );
    }
    const handleBlurWhenEdit = () => {
        formik.validateForm();
        if (demoToEdit && !rowsError) formik.handleSubmit();
    };
    const onIncidentRun = () => {
        handleClose();
        // @ts-ignore
        setDemoToRun({ ...demoToEdit, ...formik.values });
    };
    const handleFocusNumbersClick = async (e: React.MouseEvent) => {
        const inputElement = e.target as HTMLInputElement;
        if (inputElement.value && inputElement.value.indexOf('*') !== -1) {
            const inputId = inputElement.id;
            switch (inputId) {
                case 'participant0.phone':
                    await formik.setFieldValue('participant0.phone', '');
                    break;
                case 'participant1.phone':
                    await formik.setFieldValue('participant1.phone', '');
                    break;
                case 'participant2.phone':
                    await formik.setFieldValue('participant2.phone', '');
                    break;
                case 'participant3.phone':
                    await formik.setFieldValue('participant3.phone', '');
                    break;
                default:
                    break;
            }
        }
    };
    const telInputHandleChange = async (e: React.ChangeEvent) => {
        const eventTarget = e.target as HTMLInputElement;
        const offset = eventTarget.value.startsWith('1') ? 2 : 0; // In case there's a leading 1
        eventTarget.value = new AsYouType('US').input(
            eventTarget.value.substring(0, 14 + offset)
        );
        const inputValue = eventTarget.value;
        if (inputValue.endsWith(')')) {
            eventTarget.setSelectionRange(
                inputValue.length - 1,
                inputValue.length - 1
            );
        }
        formik.handleChange(e);
    };
    const participantsTouched =
        formik.touched.participant0?.name ??
        formik.touched.participant0?.phone ??
        formik.touched.participant1?.name ??
        formik.touched.participant1?.phone ??
        formik.touched.participant2?.name ??
        formik.touched.participant2?.phone ??
        formik.touched.participant3?.name ??
        formik.touched.participant3?.phone;
    const iconColor =
        demoToEdit?.customerType === CustomerType.Residential
            ? defaultTheme.colors.moonBlue
            : defaultTheme.colors.green;
    const customerTypeIcon =
        demoToEdit?.customerType === CustomerType.Residential ? (
            <HomeOutlinedIcon
                sx={{
                    fontSize: 24,
                    color: iconColor,
                }}
            />
        ) : (
            <HomeWorkOutlinedIcon
                sx={{
                    fontSize: 24,
                    color: iconColor,
                }}
            />
        );
    const getDemoTypeIcon = () => {
        if (demoToEdit?.demoType === DemoType.Existing)
            return (
                <ExplicitOutlinedIcon
                    sx={{
                        fontSize: 24,
                        color: iconColor,
                    }}
                />
            );
        if (demoToEdit?.demoType === DemoType.Prospect)
            return (
                <RequestQuoteOutlinedIcon
                    sx={{
                        fontSize: 24,
                        color: iconColor,
                    }}
                />
            );
        if (demoToEdit?.demoType === DemoType.Internal)
            return (
                <ErrorOutlineOutlinedIcon
                    sx={{
                        fontSize: 24,
                        color: iconColor,
                    }}
                />
            );
        return null;
    };

    const deliveryTypeIcon =
        demoToEdit?.deliveryType === DeliveryType.InPerson ? (
            <PeopleOutlineOutlinedIcon
                sx={{
                    fontSize: 24,
                    color: iconColor,
                }}
            />
        ) : (
            <ContactPhoneOutlinedIcon
                sx={{
                    fontSize: 24,
                    color: iconColor,
                }}
            />
        );

    return (
        <Container>
            <Header>
                <Title>{demoToEdit ? 'Demo' : 'Create Demo'}</Title>
            </Header>
            <Form onSubmit={formik.handleSubmit}>
                <DropdownsContainer>
                    {demoToEdit ? (
                        <Type>
                            {formik.values.customerType === 'smb'
                                ? formik.values.customerType.toUpperCase()
                                : formik.values.customerType
                                      .charAt(0)
                                      .toUpperCase() +
                                  formik.values.customerType.slice(1)}
                            {customerTypeIcon}
                        </Type>
                    ) : (
                        <TypeSelect
                            type={formik.values.customerType}
                            inputName="customerType"
                            handleChange={formik.handleChange}
                            onBlur={handleBlurWhenEdit}
                            error={
                                formik.touched.customerType &&
                                Boolean(formik.errors.customerType)
                            }
                            helperText={
                                formik.touched.customerType &&
                                formik.errors.customerType
                            }
                            sx={{
                                width: '100%',
                                color: defaultTheme.colors.copyTextGray,
                            }}
                            disabled={!!demoToEdit}
                        />
                    )}
                    {demoToEdit ? (
                        <Type>
                            {formik.values.demoType.charAt(0).toUpperCase() +
                                formik.values.demoType.slice(1)}
                            {getDemoTypeIcon()}
                        </Type>
                    ) : (
                        <DemoTypeSelect
                            type={formik.values.demoType}
                            handleChange={formik.handleChange}
                            error={
                                formik.touched.demoType &&
                                Boolean(formik.errors.demoType)
                            }
                            helperText={
                                formik.touched.demoType &&
                                formik.errors.demoType
                            }
                            sx={{ width: '100%' }}
                            disabled={!!demoToEdit}
                        />
                    )}{' '}
                    {demoToEdit ? (
                        <Type>
                            {formik.values.deliveryType
                                .charAt(0)
                                .toUpperCase() +
                                formik.values.deliveryType.slice(1)}
                            {deliveryTypeIcon}
                        </Type>
                    ) : (
                        <DeliveryTypeSelect
                            type={formik.values.deliveryType}
                            handleChange={formik.handleChange}
                            error={
                                formik.touched.deliveryType &&
                                Boolean(formik.errors.deliveryType)
                            }
                            helperText={
                                formik.touched.deliveryType &&
                                formik.errors.deliveryType
                            }
                            sx={{ width: '100%' }}
                            disabled={!!demoToEdit}
                        />
                    )}
                </DropdownsContainer>
                <TextField
                    fullWidth
                    id="customerName"
                    name="customerName"
                    label="Customer Name"
                    value={formik.values.customerName}
                    onChange={formik.handleChange}
                    onBlur={handleBlurWhenEdit}
                    error={
                        formik.touched.customerName &&
                        Boolean(formik.errors.customerName)
                    }
                    helperText={
                        (formik.touched.customerName &&
                            formik.errors.customerName) ||
                        ' '
                    }
                    sx={{
                        color: defaultTheme.colors.copyTextGray,
                    }}
                    disabled={!canEdit}
                />
                <TextField
                    fullWidth
                    id="customerAddress"
                    name="customerAddress"
                    label="Customer Address"
                    value={formik.values.customerAddress}
                    onChange={formik.handleChange}
                    onBlur={handleBlurWhenEdit}
                    error={
                        formik.touched.customerAddress &&
                        Boolean(formik.errors.customerAddress)
                    }
                    helperText={
                        (formik.touched.customerAddress &&
                            formik.errors.customerAddress) ||
                        ' '
                    }
                    disabled={!canEdit}
                />
                <SubTitle>Contacts</SubTitle>
                <ParticipantRow>
                    <TextField
                        fullWidth
                        id="participant0.name"
                        name="participant0.name"
                        label="Name"
                        value={formik.values.participant0.name}
                        onChange={formik.handleChange}
                        onBlur={handleBlurWhenEdit}
                        error={
                            formik.touched.participant0?.name &&
                            Boolean(formik.errors.participant0?.name)
                        }
                        helperText={
                            (formik.touched.participant0?.name &&
                                formik.errors.participant0?.name) ||
                            ' '
                        }
                        sx={{ mr: '8px' }}
                        disabled={!canEdit}
                    />
                    <TextField
                        fullWidth
                        id="participant0.phone"
                        name="participant0.phone"
                        label="Phone"
                        type="tel"
                        value={formik.values.participant0.phone}
                        onChange={telInputHandleChange}
                        onBlur={handleBlurWhenEdit}
                        onClick={handleFocusNumbersClick}
                        error={
                            formik.touched.participant0?.phone &&
                            Boolean(formik.errors.participant0?.phone)
                        }
                        helperText={
                            (formik.touched.participant0?.phone &&
                                formik.errors.participant0?.phone) ||
                            ' '
                        }
                        disabled={!canEdit}
                    />
                </ParticipantRow>
                <ParticipantRow>
                    <TextField
                        fullWidth
                        id="participant1.name"
                        name="participant1.name"
                        label="Name"
                        value={formik.values.participant1.name}
                        onChange={formik.handleChange}
                        onBlur={handleBlurWhenEdit}
                        error={
                            formik.touched.participant1?.name &&
                            Boolean(formik.errors.participant1?.name)
                        }
                        helperText={
                            (formik.touched.participant1?.name &&
                                formik.errors.participant1?.name) ||
                            ' '
                        }
                        sx={{ mr: '8px' }}
                        disabled={!canEdit}
                    />
                    <TextField
                        fullWidth
                        id="participant1.phone"
                        name="participant1.phone"
                        label="Phone"
                        type="tel"
                        value={formik.values.participant1.phone}
                        onChange={telInputHandleChange}
                        onBlur={handleBlurWhenEdit}
                        onClick={handleFocusNumbersClick}
                        error={
                            formik.touched.participant1?.phone &&
                            Boolean(formik.errors.participant1?.phone)
                        }
                        helperText={
                            (formik.touched.participant1?.phone &&
                                formik.errors.participant1?.phone) ||
                            ' '
                        }
                        disabled={!canEdit}
                    />
                </ParticipantRow>
                <ParticipantRow>
                    <TextField
                        fullWidth
                        id="participant2.name"
                        name="participant2.name"
                        label="Name"
                        value={formik.values.participant2.name}
                        onChange={formik.handleChange}
                        onBlur={handleBlurWhenEdit}
                        error={
                            formik.touched.participant2?.name &&
                            Boolean(formik.errors.participant2?.name)
                        }
                        helperText={
                            (formik.touched.participant2?.name &&
                                formik.errors.participant2?.name) ||
                            ' '
                        }
                        sx={{ mr: '8px' }}
                        disabled={!canEdit}
                    />
                    <TextField
                        fullWidth
                        id="participant2.phone"
                        name="participant2.phone"
                        label="Phone"
                        type="tel"
                        value={formik.values.participant2.phone}
                        onChange={telInputHandleChange}
                        onBlur={handleBlurWhenEdit}
                        onClick={handleFocusNumbersClick}
                        error={
                            formik.touched.participant2?.phone &&
                            Boolean(formik.errors.participant2?.phone)
                        }
                        helperText={
                            (formik.touched.participant2?.phone &&
                                formik.errors.participant2?.phone) ||
                            ' '
                        }
                        disabled={!canEdit}
                    />
                </ParticipantRow>
                <ParticipantRow>
                    <TextField
                        fullWidth
                        id="participant3.name"
                        name="participant3.name"
                        label="Name"
                        value={formik.values.participant3.name}
                        onChange={formik.handleChange}
                        onBlur={handleBlurWhenEdit}
                        error={
                            formik.touched.participant3?.name &&
                            Boolean(formik.errors.participant3?.name)
                        }
                        helperText={
                            (formik.touched.participant3?.name &&
                                formik.errors.participant3?.name) ||
                            ' '
                        }
                        sx={{ mr: '8px' }}
                        disabled={!canEdit}
                    />
                    <TextField
                        fullWidth
                        id="participant3.phone"
                        name="participant3.phone"
                        label="Phone"
                        type="tel"
                        value={formik.values.participant3.phone}
                        onChange={telInputHandleChange}
                        onBlur={handleBlurWhenEdit}
                        onClick={handleFocusNumbersClick}
                        error={
                            formik.touched.participant3?.phone &&
                            Boolean(formik.errors.participant3?.phone)
                        }
                        helperText={
                            (formik.touched.participant3?.phone &&
                                formik.errors.participant3?.phone) ||
                            ' '
                        }
                        disabled={!canEdit}
                    />
                </ParticipantRow>
                {!!rowsError && participantsTouched && (
                    <FormHelperText
                        error
                        id="rows-error"
                        sx={{ textAlign: 'center' }}
                    >
                        {rowsError}
                    </FormHelperText>
                )}
                {demoToEdit ? (
                    <ButtonsContainer>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{ width: '129px' }}
                        >
                            Close
                        </Button>
                        {demoToEdit.isArchived ? null : (
                            <Button
                                color="primary"
                                variant="contained"
                                name="create"
                                onClick={onIncidentRun}
                                disabled={!formik.isValid}
                                sx={{ width: '129px' }}
                            >
                                Start Incident
                            </Button>
                        )}
                    </ButtonsContainer>
                ) : (
                    <ButtonsContainer>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{ width: '128px' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            name="save"
                            type="submit"
                            sx={{ width: '128px' }}
                        >
                            Create Demo
                        </Button>
                    </ButtonsContainer>
                )}
            </Form>
            {loading ? <Loader /> : null}
        </Container>
    );
};
