import React from 'react';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, TextField } from '@mui/material';

interface Props {
    zoneNumber: string;
    error: boolean | undefined;
    helperText: string | false | undefined;
    disabled?: boolean;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(
            field: T_1
        ): T_1 extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    onBlur?: () => void;
}
export const ZoneNumberSelect: React.FC<Props> = ({
    zoneNumber,
    error,
    helperText,
    disabled,
    handleChange,
    onBlur,
}) => {
    return (
        <FormControl sx={{ m: '16px 0', width: '25%' }}>
            <TextField
                label="Zone #"
                error={error}
                name="zoneNumber"
                id="zoneNumber"
                onChange={handleChange}
                onBlur={onBlur}
                value={zoneNumber}
                type="number"
                variant="outlined"
                inputProps={{
                    min: 1,
                    max: 99,
                }}
                disabled={disabled}
            />

            {error && (
                <FormHelperText error id="zoneNumber-error">
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};
