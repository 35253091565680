/* eslint-disable max-len */
import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import {
    Dragndrop,
    DragndropInput,
    VideoPreview,
    Thumb,
    ThumbInner,
    InputMessage,
} from './FileInputStyled';

interface Props {
    accept: Accept;
    inputName: string;
    label: string;
    error: boolean;
    helperText: string | undefined;
    file: any | undefined;
    setFieldValue: Function;
    disabled: boolean;
    hideThumb: boolean;
    setFieldError: (field: string, value: string | undefined) => void;
    onBlur: () => void;
}

export const FileInput: React.FC<Props> = ({
    accept,
    inputName,
    label,
    error,
    helperText,
    file,
    disabled,
    setFieldValue,
    hideThumb,
    setFieldError,
    onBlur,
}) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections.length > 0) {
                setFieldError(inputName, fileRejections[0].errors[0].message);
            } else {
                setFieldValue(
                    inputName,
                    Object.assign(acceptedFiles[0], {
                        preview: URL.createObjectURL(acceptedFiles[0]),
                    })
                );
                onBlur();
            }
        },
        maxSize: 10485760,
        multiple: false,
        accept,
        disabled,
    });

    return (
        <div>
            <Dragndrop
                {...getRootProps()}
                error={error}
                data-testid="dragndrop-container"
            >
                <DragndropInput
                    {...getInputProps({ name: inputName, id: inputName })}
                    name={inputName}
                    id={inputName}
                    disabled={disabled}
                />
                <InputMessage htmlFor={inputName} disabled={disabled}>
                    {
                        // eslint-disable-next-line no-nested-ternary
                        error
                            ? helperText
                            : file
                            ? file.name
                            : `Choose your ${label} or drag it here...`
                    }
                </InputMessage>
            </Dragndrop>
            {!!file && !hideThumb && (
                <Thumb>
                    <ThumbInner>
                        <VideoPreview
                            src={file.url ? file.url : file.preview}
                            onLoad={() => {
                                URL.revokeObjectURL(file.preview);
                            }}
                        />
                    </ThumbInner>
                </Thumb>
            )}
        </div>
    );
};
