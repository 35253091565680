import styled from 'styled-components/macro';

const Container = styled.div`
    @media (max-width: 1023px) {
        width: 100vw;
    }
    width: 40vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${(props) => props.theme.fonts.header3};
    @media (max-width: 1023px) {
        position: sticky;
        top: 0;
        left: 0;
        background-color: ${(props) => props.theme.colors.white};
        z-index: 9;
    }
`;
const Title = styled.div`
    display: flex;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.header3};
    line-height: 33px;
    margin: 16px 8px 0px 16px;
`;
const SubTitle = styled.div`
    display: flex;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.header4};
    line-height: 25px;
    color: ${(props) => props.theme.colors.copyTextGray};
    margin-bottom: 16px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 16px;
    height: 100%;
`;
const DropdownsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;
`;
const ParticipantRow = styled.div`
    display: flex;
    align-items: center;
`;

const Type = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.subHeading};
    line-height: 19px;
    color: ${(props) => props.theme.colors.copyTextGray};
`;

const ButtonsContainer = styled.div`
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    gap: 8px;
`;

export {
    Container,
    Header,
    Title,
    SubTitle,
    Form,
    Type,
    DropdownsContainer,
    ParticipantRow,
    ButtonsContainer,
};
