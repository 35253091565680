import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
    AddButton,
    Filters,
    HeaderContainer,
    PageName,
    SubHeader,
} from './HeaderStyled';

interface Props {
    isTabletOrMobile: boolean;
    pageName: string;
    canCreate: boolean;
    addButtonContent: string;
    handleAddPress: () => void;
}

export const Header = ({
    isTabletOrMobile,
    pageName,
    canCreate,
    addButtonContent,
    children,
    handleAddPress,
}: React.PropsWithChildren<Props>) => {
    return (
        <HeaderContainer isTabletOrMobile={isTabletOrMobile}>
            <SubHeader>
                <PageName>{pageName}</PageName>
                <AddButton
                    size="small"
                    variant="outlined"
                    onClick={handleAddPress}
                    disabled={!canCreate}
                >
                    {isTabletOrMobile ? (
                        <AddIcon
                            sx={{
                                marginRight: isTabletOrMobile ? '0' : '12px',
                            }}
                        />
                    ) : null}
                    {isTabletOrMobile ? null : addButtonContent}
                </AddButton>
            </SubHeader>
            <Filters>{children}</Filters>
        </HeaderContainer>
    );
};
