import styled from 'styled-components/macro';
import { CustomerType } from './types';

const StyledCard = styled.div<{ type: CustomerType }>`
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 6px, rgba(0, 0, 0, 0.24) 0px 2px 6px;
    border-top: 8px solid
        ${(props) =>
            props.type === CustomerType.Residential
                ? props.theme.colors.moonBlue
                : props.theme.colors.green};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 8px;
    padding: 16px 26px;
    background-color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    :hover {
        background-color: ${(props) => props.theme.colors.backgroundGray};
    }
`;
const CreatedDate = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: ${(props) => props.theme.fonts.subtext};
    line-height: 14px;
    color: ${(props) => props.theme.colors.copyTextGray};
`;

const Zone = styled.div`
    display: flex;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.subHeading};
    line-height: 19px;
    color: ${(props) => props.theme.colors.black};
`;
const Icons = styled.div`
    display: flex;
    justify-content: space-between;
`;

export { StyledCard, CreatedDate, Zone, Icons };
