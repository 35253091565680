import styled from 'styled-components/macro';

const Container = styled.div`
    @media (max-width: 1023px) {
        width: 100vw;
    }
    width: 40vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.ADTBlue};
    background-color: ${(props) => props.theme.colors.white};
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.fonts.header3};
    margin: 16px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 0 16px 16px 16px;
    height: 100%;
`;
const ContactsHeader = styled.div`
    display: flex;
    align-items: center;
`;
const SelectAll = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.fonts.header4};
`;
const DropdownsContainer = styled.div`
    display: flex;
    gap: 8px;
`;
const ZoneContainer = styled.div`
    display: flex;
    gap: 8px;
`;
const ParticipantRow = styled.div`
    display: flex;
    align-items: center;
`;

const ButtonsContainer = styled.div`
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    gap: 8px;
`;

export {
    Container,
    Header,
    Form,
    ContactsHeader,
    SelectAll,
    DropdownsContainer,
    ZoneContainer,
    ParticipantRow,
    ButtonsContainer,
};
