import React from 'react';
import { DemosList, Empty, SectionName } from './DemosStyled';
import { ICreatedDemo } from './types';
import { Card } from './Card';
import { usePermissionCheck } from '../../redux/hooks';

interface Props {
    demos: ICreatedDemo[];
    setDemoToRun: React.Dispatch<
        React.SetStateAction<ICreatedDemo | undefined>
    >;
    setDemoToEdit: React.Dispatch<
        React.SetStateAction<ICreatedDemo | undefined>
    >;
}
export const DemosListSection: React.FC<Props> = ({
    demos,
    setDemoToRun,
    setDemoToEdit,
}) => {
    const canRun = usePermissionCheck('demos.run');
    const archivedDemos = demos.filter((demo) => demo.isArchived);
    const liveDemos = demos.filter((demo) => !demo.isArchived);
    return (
        <div>
            <SectionName>Current</SectionName>
            {liveDemos.length > 0 ? (
                <DemosList>
                    {liveDemos.map((demo) => (
                        <Card
                            key={demo.demoId}
                            canRun={canRun}
                            demo={demo}
                            setDemoToRun={setDemoToRun}
                            setDemoToEdit={() => setDemoToEdit(demo)}
                        />
                    ))}
                </DemosList>
            ) : (
                <Empty>No Demos to display</Empty>
            )}
            <SectionName>Archived</SectionName>
            {archivedDemos.length > 0 ? (
                <DemosList>
                    {archivedDemos.map((demo) => (
                        <Card
                            key={demo.demoId}
                            demo={demo}
                            canRun={canRun}
                            setDemoToRun={setDemoToRun}
                            setDemoToEdit={() => setDemoToEdit(demo)}
                        />
                    ))}
                </DemosList>
            ) : (
                <Empty>No Demos to display</Empty>
            )}
        </div>
    );
};
