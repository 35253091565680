/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import {
    useAppDispatch,
    useAppSelector,
    usePermissionCheck,
} from '../redux/hooks';
import { Loader } from './Loader';
import { fetchUser } from '../redux/userProfileSlice';

export const PrivateRoute = ({ permission }: { permission: string }) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { oktaAuth, authState } = useOktaAuth();
    const { profile, loading } = useAppSelector((state) => state.userProfile);
    const userHasRequiredRole = usePermissionCheck(permission);

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(
                window.location.href,
                window.location.origin
            );
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }
        if (authState?.isAuthenticated) dispatch(fetchUser());
    }, [oktaAuth, authState, authState?.isAuthenticated, dispatch]);

    if (loading === 'pending' || !authState || !authState?.isAuthenticated) {
        return <Loader />;
    }

    if (profile && !userHasRequiredRole) {
        return <Navigate to="*" state={{ from: location }} />;
    }

    return <Outlet />;
};
