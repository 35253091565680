/* eslint-disable no-restricted-syntax */
import { toast } from 'react-toastify';
import { config } from '../config';
import {
    GetMediaListResponse,
    ICreatedMedia,
    IMedia,
} from '../pages/media/types';
import { get, patch, post, remove } from './axiosService';

export const getMediaList = async (search?: string, filter?: string) => {
    try {
        const response = await get<GetMediaListResponse>(
            `${config.clients.mediaClient.baseUrl}list`,
            { search, filter }
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const getMedia = async ({ id }: { id: string }) => {
    try {
        const response = await get<ICreatedMedia>(
            `${config.clients.mediaClient.baseUrl}${id}`
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const createMedia = async (data: IMedia): Promise<any> => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if (value) formData.append(key, value);
    }
    try {
        const response = await post(
            config.clients.mediaClient.baseUrl,

            formData
        );

        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const updateMedia = async ({
    mediaId,
    data,
}: {
    mediaId: string;
    data: IMedia;
}): Promise<any> => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if (value) formData.append(key, value);
    }
    formData.append('mediaId', mediaId);
    try {
        const response = await patch(
            `${config.clients.mediaClient.baseUrl}`,
            formData
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const deleteMedia = async (id: string): Promise<any> => {
    try {
        const response = await remove(
            `${config.clients.mediaClient.baseUrl}${id}`
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};
