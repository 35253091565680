import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro';

const LoaderContainer = styled.div`
    pointer-events: all;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
`;

export const Loader: React.FC = () => {
    return (
        <LoaderContainer data-testid="loader">
            <Backdrop
                sx={{
                    color: '#fff',
                    pointerEvents: 'none',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </LoaderContainer>
    );
};
