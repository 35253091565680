import { ICreatedMedia } from '../media/types';

export enum CustomerType {
    Smb = 'smb',
    Residential = 'residential',
}

export interface Template {
    type: CustomerType.Smb | CustomerType.Residential;
    zoneNumber: string;
    zoneDescription: string;
    mediaId: string;
}

export interface CreatedTemplate {
    zoneTemplateId: string;
    createdDate: string;
    type: CustomerType.Smb | CustomerType.Residential;
    zoneNumber: string;
    zoneDescription: string;
    media: ICreatedMedia | null;
}

export interface GetTemplatesListResponse {
    count: number;
    items: CreatedTemplate[];
}
