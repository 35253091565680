import styled from 'styled-components/macro';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
const PageName = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.fonts.header1};
    line-height: 65px;
    color: ${(props) => props.theme.colors.ADTBlue};
    @media (max-width: 1023px) {
        margin: 16px 0;
        font-size: 28px;
        line-height: 30px;
        display: flex;
        justify-content: center;
        width: 100%;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 16px;
    height: 100%;
`;

const ProfileInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 50%;
    align-self: center;
    @media (max-width: 1023px) {
        width: 100%;
    }
`;

export { Container, ProfileInfo, PageName, Form };
