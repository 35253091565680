import styled from 'styled-components/macro';

export const Thumb = styled.aside`
    display: inline-flex;
    border-radius: 2px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 150px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
`;
export const ThumbInner = styled.div`
    display: flex;
    min-width: 0;
    overflow: hidden;
    margin-bottom: 8px;
`;

export const VideoPreview = styled.video`
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
`;
