import React from 'react';
import FormControl from '@mui/material/FormControl';
import {
    Select,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    SxProps,
    Theme,
} from '@mui/material';
import { VideoPreview, Thumb, ThumbInner } from './MediaSelectStyled';
import { ICreatedMedia } from '../media/types';

interface Props {
    selectedMedia: string | null;
    error: boolean | undefined;
    helperText: string | false | undefined;
    none?: boolean;
    sx: SxProps<Theme>;
    mediaList: ICreatedMedia[] | undefined;
    templateHasMedia: boolean;
    disabled?: boolean;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(
            field: T_1
        ): T_1 extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    onBlur?: () => void;
}
export const MediaSelect: React.FC<Props> = ({
    selectedMedia,
    error,
    helperText,
    none,
    sx,
    mediaList,
    disabled,
    templateHasMedia,
    handleChange,
    onBlur,
}) => {
    const selectedMediaItem = mediaList?.find(
        (media) => media.mediaId === selectedMedia
    );

    return (
        <FormControl sx={sx} data-testid="media-thumb-container">
            <InputLabel id="mediaId-label" sx={{ backgroundColor: 'white' }}>
                {templateHasMedia ? 'Media' : 'Add Media'}
            </InputLabel>
            <Select
                name="mediaId"
                id="mediaId"
                labelId="mediaId-label"
                onChange={handleChange}
                onBlur={onBlur}
                variant="standard"
                value={selectedMedia}
                input={<OutlinedInput label="Media" error={error} />}
                disabled={disabled}
            >
                {none ? (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                ) : null}
                {mediaList?.map((media) => (
                    <MenuItem value={media.mediaId} key={media.mediaId}>
                        <span>{media.mediaDescription}</span>
                    </MenuItem>
                ))}
            </Select>
            {error && (
                <FormHelperText error id="mediaId-error">
                    {helperText}
                </FormHelperText>
            )}
            {selectedMediaItem && (
                <Thumb data-testid="media-thumb">
                    <ThumbInner>
                        <VideoPreview
                            src={selectedMediaItem.mediaFile?.url}
                            controls={false}
                        />
                    </ThumbInner>
                </Thumb>
            )}
        </FormControl>
    );
};
