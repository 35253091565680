import React from 'react';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { ICreatedMedia } from './types';
import {
    StyledCard,
    Preview,
    PreviewThumbnail,
    CreatedDate,
    Description,
    Icons,
    VideoContainer,
} from './MediaCardStyled';
import { CustomerType } from '../templates/types';
import { defaultTheme } from '../../shared/styles/theme';

interface Props {
    media: ICreatedMedia;
    setMediaToEdit: () => void;
    setClickedMedia: () => void;
    clickedMedia: Map<string, string>;
}

export const MediaCard: React.FC<Props> = ({
    media,
    setMediaToEdit,
    setClickedMedia,
    clickedMedia,
}) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'America/Los_Angeles',
    } as const;
    const mediaIconAltText =
        media.isAssignedToTemplate &&
        media.linkedTemplates &&
        media.linkedTemplates.length > 0
            ? `Linked Templates:\n${media.linkedTemplates
                  .map((template) => template.zoneDescription)
                  .filter((description) => description) // Remove any undefined or empty descriptions
                  .join(', \n')}`
            : '';

    return (
        <StyledCard
            type={media.customerType}
            onClick={setMediaToEdit}
            data-testid="media-card"
        >
            <CreatedDate>
                Created Date:{' '}
                <b>
                    {new Intl.DateTimeFormat('en-US', options).format(
                        new Date(media.createdDate)
                    )}
                </b>
            </CreatedDate>
            <Description>{media.mediaDescription}</Description>
            <VideoContainer>
                {clickedMedia.get(media.mediaId) ? (
                    <Preview
                        src={media.mediaFile?.url}
                        aria-label="Video"
                        controls
                        autoPlay
                    />
                ) : (
                    <PreviewThumbnail
                        src={media.mediaFile?.thumbnail}
                        aria-label="Thumbnail"
                        onClick={(e) => {
                            e.stopPropagation();
                            setClickedMedia();
                        }}
                    />
                )}
            </VideoContainer>
            <Icons>
                {media.customerType === CustomerType.Residential ? (
                    <HomeOutlinedIcon
                        sx={{
                            fontSize: 50,
                            color: defaultTheme.colors.moonBlue,
                        }}
                        titleAccess="Residential"
                    />
                ) : (
                    <HomeWorkOutlinedIcon
                        sx={{
                            fontSize: 50,
                            color: defaultTheme.colors.green,
                        }}
                        titleAccess="Small/Medium Business (SMB)"
                    />
                )}
                <InsertLinkOutlinedIcon
                    sx={{
                        fontSize: 50,
                        color: media.isAssignedToTemplate
                            ? defaultTheme.colors.ADTBlue
                            : defaultTheme.colors.grey4,
                    }}
                    titleAccess={mediaIconAltText}
                />
            </Icons>
        </StyledCard>
    );
};
