import styled from 'styled-components/macro';

const Container = styled.div`
    width: 432px;
    height: 100%;
    border-radius: 10px;
    @media (max-width: 1023px) {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

const Title = styled.div`
    height: 24px;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.header3};
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px;
`;

const Message = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
    padding: 16px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
`;

export { Container, Title, Message, ButtonsContainer };
