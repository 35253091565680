import { toast } from 'react-toastify';
import { config } from '../config';
import {
    CreatedTemplate,
    GetTemplatesListResponse,
    Template,
} from '../pages/templates/types';
import { get, patch, post, remove } from './axiosService';

export const getTemplates = async (search?: string, filter?: string) => {
    try {
        const response = await get<GetTemplatesListResponse>(
            `${config.clients.templatesClient.baseUrl}list`,

            { search, filter }
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const getTemplate = async ({ id }: { id: string }) => {
    try {
        const response = await get<CreatedTemplate>(
            `${config.clients.templatesClient.baseUrl}${id}`
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const createTemplate = async (data: Template): Promise<any> => {
    try {
        const response = await post(
            config.clients.templatesClient.baseUrl,
            data
        );

        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const updateTemplate = async ({
    zoneTemplateId,
    data,
}: {
    zoneTemplateId: string;
    data: Omit<Partial<CreatedTemplate>, 'media'>;
}): Promise<any> => {
    try {
        const response = await patch(
            `${config.clients.templatesClient.baseUrl}${zoneTemplateId}/`,
            data
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const deleteTemplate = async (id: string): Promise<any> => {
    try {
        const response = await remove(
            `${config.clients.templatesClient.baseUrl}${id}`
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};
