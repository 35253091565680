import { toast } from 'react-toastify';
import { config } from '../config';
import { ICreatedDemo, Incident } from '../pages/demos/types';
import { get, post } from './axiosService';

export const getIncindent = async ({ id }: { id: string }) => {
    try {
        const response = await get<ICreatedDemo>(
            `${config.clients.incidentClient.baseUrl}${id}`
        );
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};

export const createIncident = async (data: Incident): Promise<any> => {
    try {
        const response = await post(
            config.clients.incidentClient.baseUrl,
            data
        );
        if (response) {
            toast.success('The incident started successfully.', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        return response;
    } catch (error) {
        toast.error('Server error. Please try again', {
            position: toast.POSITION.TOP_CENTER,
        });
        return null;
    }
};
