import { createDemo, updateDemo } from '../../clients/demosClient';
import { ICreatedDemo, IDemo } from './types';

export function getModifiedValues(
    values: IDemo,
    initialValues: IDemo
): Partial<IDemo> {
    const modifiedValues: Partial<IDemo> = {};

    Object.keys(values).forEach((key) => {
        const valueKey = key as keyof IDemo;
        if (
            valueKey === 'participant0' ||
            valueKey === 'participant1' ||
            valueKey === 'participant2' ||
            valueKey === 'participant3'
        ) {
            const participantModifiedValues: Partial<
                (typeof values)['participant0']
            > = {};

            Object.keys(values[valueKey]).forEach((participantKey) => {
                const participantValueKey =
                    participantKey as keyof (typeof values)['participant0'];

                if (
                    values[valueKey][participantValueKey] !==
                        initialValues[valueKey][participantValueKey] &&
                    values[valueKey][participantValueKey]
                        ?.toString()
                        ?.indexOf('*') === -1
                ) {
                    // @ts-ignore
                    participantModifiedValues[participantValueKey] =
                        values[valueKey][participantValueKey];
                }
            });

            if (Object.keys(participantModifiedValues).length > 0) {
                modifiedValues[valueKey] = participantModifiedValues;
            }
        } else if (
            JSON.stringify(values[valueKey]) !==
            JSON.stringify(initialValues[valueKey])
        ) {
            modifiedValues[valueKey] = values[valueKey] as any;
        }
    });

    return modifiedValues;
}

export const handleDemoSubmit = async (
    values: any,
    demoToEdit: ICreatedDemo | undefined | null,
    initialValues: any,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setValues: (values: any) => {},
    handleClose: () => void
) => {
    const modifiedValues = getModifiedValues(values, initialValues);
    const modifiedValuesEmpty =
        Object.keys(modifiedValues).length === 0 &&
        modifiedValues.constructor === Object;
    if (demoToEdit && modifiedValuesEmpty) return;

    if (demoToEdit) {
        setLoading(true);
        const result = await updateDemo(demoToEdit.demoId, modifiedValues);
        const clearedValues = { ...initialValues };
        Object.keys(initialValues).forEach((key) => {
            if (clearedValues[key]) {
                clearedValues[key] = result[key];
            }
        });
        setValues(clearedValues);
        setLoading(false);
    } else {
        setLoading(true);
        const response = await createDemo(values as IDemo);
        setLoading(false);
        if (response) handleClose();
    }
};

export const handleSearch = (
    event: React.ChangeEvent<HTMLInputElement>,
    setPage: React.Dispatch<React.SetStateAction<number>>,
    setSearch: React.Dispatch<React.SetStateAction<string>>
) => {
    if (event.target.value.length > 2 || event.target.value === '') {
        setPage(1);
        setSearch(event.target.value);
    }
};

export const onModalClose = (
    setOpenCreateDemoModal: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenDemoSubmodal: React.Dispatch<React.SetStateAction<boolean>>,
    setDemoToEdit: React.Dispatch<
        React.SetStateAction<ICreatedDemo | undefined>
    >,
    setDemoToRun: React.Dispatch<
        React.SetStateAction<ICreatedDemo | undefined>
    >,
    fetchDemos: () => Promise<void>
): void => {
    setOpenCreateDemoModal(false);
    setOpenDemoSubmodal(false);
    setDemoToEdit(undefined);
    setDemoToRun(undefined);
    fetchDemos();
};
