import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { OktaAuth, OktaAuthOptions, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar } from './components/Navbar';
import {
    ContentWrapper,
    NavWrapper,
    NoRoute,
    Wrapper,
} from './MainLayoutStyled';
import { routes } from './routes';
import Profile from './pages/profile/Profile';
import { PrivateRoute } from './components/PrivateRoute';
import { Loader } from './components/Loader';
import { defaultTheme } from './shared/styles/theme';

const config: OktaAuthOptions = {
    issuer: process.env.REACT_APP_OKTA_DOMAIN ?? '',
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    postLogoutRedirectUri: window.location.origin,
    scopes: ['openid', 'email', 'groups', 'profile'],
    transformAuthState: async (authInstance, _authState) => {
        const authState = _authState;
        if (process.env.NODE_ENV === 'test') {
            authState.isAuthenticated = true;
            return authState;
        }
        if (!authState.isAuthenticated) {
            return authState;
        }
        const user = await authInstance.token.getUserInfo();
        authState.isAuthenticated = !!user;
        authState.user = user;
        return authState;
    },
};
export const oktaAuth = new OktaAuth(config);

const DRAWER_SX = {
    '.MuiDrawer-paperAnchorRight': {
        background: `linear-gradient(0deg, rgba(170, 199, 255, 0.11),
     rgba(170, 199, 255, 0.11)), #1B1B1D`,
        boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15)',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))',
        borderRadius: '12px 0px 0px 12px',
    },
};

const MainLayout: React.FC = () => {
    const isTabletOrMobile = useMediaQuery('(max-width: 1023px)');
    const [openMenu, setOpenMenu] = useState(false);
    const navigate = useNavigate();

    const restoreOriginalUri = async (
        _oktaAuth: OktaAuth,
        originalUri: string
    ) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };
    return (
        <Wrapper data-testid="main-layout">
            {isTabletOrMobile ? (
                <NavWrapper>
                    <IconButton
                        size="large"
                        onClick={() => setOpenMenu(!openMenu)}
                    >
                        <MenuIcon sx={{ color: defaultTheme.colors.ADTBlue }} />
                    </IconButton>
                </NavWrapper>
            ) : (
                <NavWrapper>
                    <Navbar />
                </NavWrapper>
            )}
            <ContentWrapper>
                <Security
                    oktaAuth={oktaAuth}
                    restoreOriginalUri={restoreOriginalUri}
                >
                    <Routes>
                        {routes.map((route, i) => (
                            <Route
                                key={i}
                                path={route.path}
                                element={
                                    <PrivateRoute
                                        permission={route.permission}
                                    />
                                }
                            >
                                <Route path="" element={<route.component />} />
                            </Route>
                        ))}
                        <Route
                            path="/profile"
                            element={<PrivateRoute permission="profile.view" />}
                        >
                            <Route path="" element={<Profile />} />
                        </Route>

                        <Route
                            path="/login/callback"
                            element={
                                <LoginCallback loadingElement={<Loader />} />
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <NoRoute>
                                    There is no such page or you have no rights
                                    to get there.
                                </NoRoute>
                            }
                        />
                    </Routes>
                </Security>
            </ContentWrapper>
            <Drawer
                anchor="left"
                open={openMenu}
                onClose={() => {
                    setOpenMenu(false);
                }}
                sx={DRAWER_SX}
            >
                <Navbar onRouteClick={() => setOpenMenu(false)} />
            </Drawer>
            <ToastContainer />
        </Wrapper>
    );
};

export default MainLayout;
